import React, {useState, useEffect, useLayoutEffect}  from "react";
import {highlightNavlink}                             from "../global/Helper";
import * as helper                                    from "../global/Helper";
import ReactTooltip                                   from 'react-tooltip';
import $                                              from "jquery";
import {addContactUs, getFAQ}                         from "../api/webAPI";
import Swal                                           from "sweetalert2";
import Select                                         from 'react-select';
import giftLogo                                       from "../../assets/images/gift.png";
import { appUrl }                                     from "../global/Helper";
import manuser                                        from "../../assets/images/man-user.png";
import three                                          from "../../assets/images/three-users.png";
import { Helmet }                                     from "react-helmet";


export const ContactModal = (props) => {

  const [contactData, setContactData]  = useState({Subject: "Enquiry of Enterprise Plan"});

  const EmployeeStrength = [
    {   value: '6-10',        label: '6-10'     },
    {   value: '11-25',       label: '11-25'    },
    {   value: '26-50',       label: '26-50'    },
    {   value: '50-100',      label: '50-100'   },
    {   value:'100-Above',    label: '100-Above'}
  ];

  const ContactDataHandler =(key, value) => {
    setContactData(d=>{
      return {...d,[key]:value};
    });
  };

  
  const submitContact = async (e) => {
    e.preventDefault();
    var form = $('#contact-form');

    form.validate().destroy();
    form.validate({
      rules:{
        ContactName:{
          required:true
        },
        ContactEmail:{
          required:true,
        },
        ContactNo:{
          required:true,
        },
      }
    });

    if(!form.valid()){
      if ($("#ContactName-error").text() !== "") {
        $("#txtContactName").addClass("is-invalid");
      }
      if ($("#ContactEmail-error").text() !== "") {
        $("#txtContactEmail").addClass("is-invalid");
      }
      if($("#ContactNo-error").text()!==""){
        $("#ContactNo").addClass("is-invalid");
      }
      return;
    }

    try {
      helper.StartProcessing($("#btnSubmit"));
      await addContactUs(contactData);
      $("#Contact-modal").modal("hide");
      Swal.fire({
        icon              : "success",
        titleText         : "Request Submitted",
        text              : "Thank You..!",
        showConfirmButton : false,
        timer             : 1500,
      });
    } catch (err) {
      helper.StopProcessing($("#btnSubmit"));
      Swal.fire({
        icon                  : "error",
        titleText             : "Error!",
        text                  : err.message,
        buttonsStyling        : false,
        confirmButtonClass    : "btn btn-brand",
      });
    }  
  }
  

  //Modal Opener
  useEffect(() => {
    $("#Contact-modal").modal({
      backdrop: "static",
    });

    $("#Contact-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      props.onDismissModal();
    });

    $("#Contact-modal").modal("toggle");
  }, [props.show]);



  return (
    //  <!--Modal Contact -->
    <div
      className="modal fade bd-example-modal-lg"
      id="Contact-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalContactTitle"
      aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalContactTitle">
              Contact Us
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div id="modalContactHolder">
              <div id="messageContactHolder"></div>
              <form id="contact-form" className="contact">
                <input
                  className="contact-input white-input margin-t0"
                  name="ContactName"
                  id="ContactName"
                  placeholder="Name"
                  type="text"
                  value={contactData.Name || ""}
                  onChange={({ target }) =>ContactDataHandler("Name", helper.titleCase(target.value))}
                />
                <input
                  className="contact-input white-input"
                  name="ContactEmail"
                  id="ContactEmail"
                  placeholder="Email"
                  type="email"
                  value={contactData.Email || ""}
                  onChange={({ target }) =>ContactDataHandler("Email", target.value)}
                />
                 <input
                  className="contact-input white-input"
                  name="CompanyName"
                  id="CompanyName"
                  placeholder="Company Name"
                  type="text"
                  value={contactData.CompanyName || ""}
                  onChange={({ target }) =>ContactDataHandler("CompanyName",helper.titleCase(target.value))}
                />
                <input
                  className="contact-input white-input"
                  name="ContactNo"
                  id="ContactNo"
                  placeholder="Contact No.."
                  type="text"
                  value={contactData.ContactNo || ""}
                  onChange={({ target }) =>ContactDataHandler("ContactNo", target.value)}
                />
                <Select
                  id="selectEmployee"
                  name="selectEmployee"
                  placeholder="Select Employee Strength"
                  className="margin-t20"
                  options={EmployeeStrength}
                  onChange={(selected)=>{
                    if (selected) {
                      ContactDataHandler("EmployeeStrength",selected.value)
                    }
                  }}
                 
                />
                <input
                  className="contact-input white-input"
                  name="Subject"
                  id="Subject"
                  placeholder="Subject"
                  type="text"
                  disabled
                  value={ contactData.Subject}
                />
                <textarea
                  className="contact-commnent white-input"
                  rows="2"
                  cols="20"
                  name="ContactMessage"
                  id="ContactMessage"
                  placeholder="Message"
                  value={contactData.Message || ""}
                  onChange={({ target }) =>ContactDataHandler("Message", target.value)}>
                </textarea>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal">
              Close
            </button>
            <button type="button" id="btnSubmit" className="btn btn-primary" onClick={submitContact}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    // {/* <!-- /#Modal --> */}
  );
};



const Pricing =() => {

  
  const [type,                           setType]   =   useState('annual');
  const [pricingData,                 setPricing]   =   useState({});
  const [FAQData,                     setFAQData]   =   useState([]);
  const [showContactModal,   setShowContactModal]   =   useState(false);
  const [data,                           setData]   =   useState({
    policiesUpload                :"Policy document upload per year",
    clients                       :"Primary Clients limited to 1000 for Solo plan . Family members and employee unlimited",
    fileStorage                   :"File uploaded to cloud server  i.e brochures , wordings , claims forms",
    subAgency                     :"Manage sub agents business and generate reports",
    employeeBenefits              :"Clients can manage their employee group health and accident policy through web and mobile app",
    BusinessMobileApp             :"InsureBox Business mobile app to manage the policies and clients",
    TechnicalAccountManagement    : "Technical Account Managers will be your trusted advisor for service-related activities",
    PriorityResponse              : "Support turn around time as per set plans"
  })
  

  useLayoutEffect(() => {
    if(type === "annual"){
      $(".btn-monthly").removeClass('active');
      $(".btn-annual").addClass('active');
    }else{
      $(".btn-annual").removeClass('active');
      $(".btn-monthly").addClass('active');
    }

    switch(type){
      case 'monthly':
        setPricing({solo:1199, team:2999, teamplus:4999 ,ep:5999, solo1:899, team1:2499, teamplus1:3999, ep1:7999, type:'month, billed monthly' });
        break;
      default :
        setPricing({solo:1199, team:2999, teamplus: 4999, ep:6599, soloY1:499, teamY1:1999, teamplusY1:2999, epY1:3999,type:'month, billed annually' });
        break;
    }

    
   
  }, [type])


  useEffect(() => {
    let ignore = false;
    const fetchFAQ = async () =>{
      try {
        const response = await getFAQ();
        if(response.length > 0) setFAQData(response);
      } catch (error) {
        Swal.fire({
          icon                : "error",
          titleText           : "Error!",
          text                : error.message,
          buttonsStyling      : false,
          confirmButtonClass  : "btn btn-brand",
        });
      }
    }
    if(!ignore){
      highlightNavlink('menuPricing');
      fetchFAQ();
    }
    return ()=>{
      ignore = true;
    }
  }, []);


  useEffect(() => {
    const maxHeight = $($(".pricing-list").sort((a, b) => $(b).height() - $(a).height())[0]).height();
    $(".pricing-list").css('min-height', `${maxHeight}px`);
  })


  return(
    <div>
        <Helmet>
          <title> Insurance Software - Price | Insurebox.co </title>
          <meta name="description"
          content="Insurance agent management system by Insurebox Software pricing start at 899 Rs/-. All pricing packages are backed up by 15-days money back guarantee. Take your Insurance Business to next level."/>
        </Helmet>
      <section className="section-grey pb-2" id="pricing"  onScroll={() => highlightNavlink('menuPricing')}>
        <div className="container">
          <div className="row" >
            <div className="col-md-12 text-center padding-bottom-10">
              <p className="section-subtitle margin-t20 mb-1">All pricing packages are backed up by 15-days money back guarantee.</p>
              <p className="section-subtitle">Take your Insurance Business to next level ! Buy an annual plan and get upto 60% Off!</p>
            </div>
          </div>
          <div className="row margin-bottom-60" >
            <div className="col-md-12 text-center ">
              <div className="pricing">
                <div className="btn-group pricing-btns" role="group">
                  <button type="button" className="btn btn-wide btn-uppercase btn-bolder btn-monthly"  onClick={()=> setType('monthly')}>
                    Monthly Plans
                  </button>
                  <button type="button" className="btn btn-wide btn-uppercase btn-bolder btn-annual "  onClick={()=> setType('annual')}>
                    Annual Plans  Upto 60% Off!<span><img  className="img-gift" id="giftLogo" src={giftLogo} alt="gift-logo" /></span>  
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* PRICING ABOVE MD */}
          <div className="row pricing-row-md">
            <div className="col-lg-3 col-md-6 col-12  ">

              {/* Header Solo */}
              <div className="price-box">
                <ul className="pricing-list">
                  <li className="price-title">Solo</li>
                  { type === "annual" &&
                  <li className="price-value"><strike className="striker">₹{pricingData.solo}</strike>₹{pricingData.soloY1}</li> || <li className="price-value"><strike className="striker">₹{pricingData.solo}</strike>₹{pricingData.solo1}</li>}
                  <li className="price-users"><img className="user-img padding-r5" src={manuser} alt="" ></img> 1 User </li>
                  <li className="price-subtitle">Per {pricingData.type}</li>
                  <li className="price-text">
                    For Individual Insurance Advisors. 
                  </li>
                </ul>
                <div className="pricing-extra">
                  <p className="price-tag-line">No credit card required</p>
                  <button className="btn btn-primary"onClick={appUrl}>Start for free</button>
                  <span className="btn btn-light d-md-none d-block " data-toggle="collapse"  data-target="#collapseSolo" aria-expanded="false" aria-controls="collapseSolo" >
                    View details 
                  </span>
                </div>
                {/* Solo Collapse */}
                <div className="collapse" id="collapseSolo">
                  <table className="table  pricing-table" >
                    <thead>
                      <tr>
                        <th className="text-left "> <div className=" first-th"> Features </div></th>
                        <th > <div className="last-th"> Solo </div> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td  className="text-left">Licensed User</td>
                        <td className="last-cell"> 1 </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Policies Upload
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='solo-policiesUpload'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='solo-policiesUpload' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.policiesUpload}</span>
                          </ReactTooltip>
                          </td>
                        <td className="last-cell">10,000</td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Clients
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='solo-clients'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip  className="tooltip-sm" id='solo-clients' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.clients}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell">1000</td>
                      </tr>
                      <tr>
                        <td className="text-left">Quotations</td>
                        <td className="last-cell">Unlimited</td>
                      </tr>
                      <tr>
                        <td className="text-left "> 
                          Storage 
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='solo-fileStorage'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='solo-fileStorage' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.fileStorage}</span>
                          </ReactTooltip>
                        </td>
                        <td className="last-cell"> 10 Gb  </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Sub Agency
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='solo-subAgency'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='solo-subAgency' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.subAgency}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"> - </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Employee Benefits
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='solo-employeeBenefits'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='solo-employeeBenefits' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.employeeBenefits}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"> - </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Business Mobile App
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='solo-BusinessMobileApp'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip  className="tooltip-sm" id='solo-BusinessMobileApp' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.BusinessMobileApp}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"><i className="fas fa-check blue"></i></td>
                      </tr>
                      <tr>
                        <td className="text-left">
                        Technical Account Management
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='solo-TechnicalAccountManagement'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='solo-TechnicalAccountManagement' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.TechnicalAccountManagement}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell">-</td>
                      </tr>
                      <tr>
                        <td className="text-left">
                        Priority Response
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='solo-PriorityResponse'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='solo-PriorityResponse' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.PriorityResponse}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell">48hrs</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Header Team */}
            <div className="col-lg-3 col-md-6 col-12 ">
              <div className="side-ribbon blue"><span> Most Popular </span></div>
              <div className="price-box">
                <ul className="pricing-list">
                  <li className="price-title">Team</li>
                  { type === "annual" &&
                  <li className="price-value"><strike className="striker">₹{pricingData.team}</strike>₹{pricingData.teamY1}</li>  || <li className="price-value"><strike className="striker">₹{pricingData.team}</strike>₹{pricingData.team1}</li>}
                  <li className="price-users"><img className="user-img padding-r5" src={three} alt="" ></img> 3 Users </li>
                  <li className="price-subtitle">Per {pricingData.type}</li>
                  <li className="price-text">For small team's with advanced features.
                  </li>
                </ul>
                <div className="pricing-extra">
                  <p className="price-tag-line">No credit card required</p>
                  <button className="btn btn-primary-team"onClick={appUrl}>Start for free</button>
                  <span className="btn btn-light d-md-none d-block " data-toggle="collapse"  data-target="#collapseTeam" aria-expanded="false" aria-controls="collapseTeam" >
                    View details 
                  </span>
                </div>
                {/* Team Collapse */}
                <div className="collapse" id="collapseTeam">
                  <table className="table  pricing-table" >
                    <thead>
                      <tr>
                        <th className="text-left "> <div className=" first-th"> Features </div></th>
                        <th > <div className="last-th"> Team  </div> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td  className="text-left">Licensed Users</td>
                        <td className="last-cell"> 3 </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Policies Upload
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='team-policiesUpload'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='team-policiesUpload' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.policiesUpload}</span>
                          </ReactTooltip>
                          </td>
                        <td className="last-cell">Unlimited</td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Clients
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='team-clients'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='team-clients' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.clients}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell">Unlimited</td>
                      </tr>
                      <tr>
                        <td className="text-left">Quotations</td>
                        <td className="last-cell">Unlimited</td>
                      </tr>
                      <tr>
                        <td className="text-left "> 
                          File Storage 
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='taem-fileStorage'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='taem-fileStorage' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.fileStorage}</span>
                          </ReactTooltip>
                        </td>
                        <td className="last-cell"> Unlimited  </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Sub Agency
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='team-subAgency'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='team-subAgency' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.subAgency}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"> <i className="fas fa-check blue"></i> </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Employee Benefits
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='team-employeeBenefits'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='team-employeeBenefits' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.employeeBenefits}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"> - </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Business Mobile App
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='teamBusinessMobileApp'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='teamBusinessMobileApp' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.BusinessMobileApp}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"><i className="fas fa-check blue"></i></td>
                      </tr>
                      <tr>
                        <td className="text-left">
                        Technical Account Management
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='team-TechnicalAccountManagement'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='team-TechnicalAccountManagement' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.TechnicalAccountManagement}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell">-</td>
                      </tr>
                      <tr>
                        <td className="text-left">
                        Priority Response
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='team-PriorityResponse'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='team-PriorityResponse' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.PriorityResponse}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell">48hrs</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Header TeamPlus */}
            <div className="col-lg-3 col-md-6 col-12  ">
              <div className="price-box">
                <ul className="pricing-list">
                  <li className="price-title">Team Plus</li>
                  { type === "annual" &&
                  <li className="price-value"><strike className="striker">₹{pricingData.teamplus}</strike>₹{pricingData.teamplusY1}</li>  || <li className="price-value"><strike className="striker">₹{pricingData.teamplus}</strike>₹{pricingData.teamplus1}</li>}
                  <li className="price-users"><img className="user-img padding-r5" src={three} alt="" ></img> 5 Users </li>
                  <li className="price-subtitle">Per {pricingData.type}</li>
                  <li className="price-text strong">
                  For growing teams and business with advanced features and technical service manager.
                  </li>
                </ul>
                <div className="pricing-extra">
                  <p className="price-tag-line">No credit card required</p>
                  <button className=" btn btn-primary"onClick={appUrl}>Start for free</button>
                  <span className="btn btn-light d-md-none d-block " data-toggle="collapse"  data-target="#collapseTeamplus" aria-expanded="false" aria-controls="collapseTeamplus" >
                    View details 
                  </span>
                </div>

                {/* TeamPlus Collapse */}
                <div className="collapse" id="collapseTeamplus">
                  <table className="table  pricing-table" >
                    <thead>
                      <tr>
                        <th className="text-left "> <div className=" first-th"> Features </div></th>
                        <th > <div className="last-th"> Team <i className="fa fa-plus"></i>      </div> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td  className="text-left">Licensed Users</td>
                        <td className="last-cell"> 5 </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Policies Upload
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='teamplus-policiesUpload'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='teamplus-policiesUpload' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.policiesUpload}</span>
                          </ReactTooltip>
                          </td>
                          <td className="last-cell">Unlimited</td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Clients
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='teamplus-clients'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='teamplus-clients' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.clients}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell">Unlimited</td>
                      </tr>
                      <tr>
                        <td className="text-left">Quotations</td>
                        <td className="last-cell">Unlimited</td>
                      </tr>
                      <tr >
                        <td className="text-left "> 
                          Storage 
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='teamplus-fileStorage'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='teamplus-fileStorage' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.fileStorage}</span>
                          </ReactTooltip>
                        </td>
                        <td className="last-cell">Unlimited</td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Sub Agency
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='teanplus-subAgency'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm"id='teanplus-subAgency' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.subAgency}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"> <i className="fas fa-check blue"></i> </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Employee Benefits
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='teanplus-employeeBenefits'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='teanplus-employeeBenefits' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.employeeBenefits}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"><i className="fas fa-check blue"></i></td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Business Mobile App
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='teanplus-BusinessMobileApp'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='teanplus-BusinessMobileApp' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.BusinessMobileApp}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"><i className="fas fa-check blue"></i></td>
                      </tr>
                      <tr>
                        <td className="text-left">
                        Technical Account Management
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='teamplus-TechnicalAccountManagement'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='teamplus-TechnicalAccountManagement' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.TechnicalAccountManagement}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"><i className="fas fa-check blue"></i></td>
                      </tr>
                      <tr>
                        <td className="text-left">
                        Priority Response
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='teamplus-PriorityResponse'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='teamplus-PriorityResponse' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.PriorityResponse}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell">24hrs</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Header Enterprise */}
            <div className="col-lg-3 col-md-6 col-12 ">
              <div className="price-box ">
                <ul className="pricing-list">
                  <li className="price-title">Enterprise</li>
                  <li className="price-value"> Custom Plans </li>            
                  <li className="price-subtitle">As per requirements</li>
                  <li className="price-text white-text">For large insurance agencies / brokers with advanced features, customized requirements and technical service manager, kindly contact us.</li>
                </ul>
                <div className="pricing-extra">
                  <p className="price-tag-line">No credit card required</p>
                  <button className="btn btn-primary" onClick={()=>setShowContactModal(true)}>Contact Us</button>
                  <span className="btn btn-light d-md-none d-block " data-toggle="collapse"  data-target="#collapseEp" aria-expanded="false" aria-controls="collapseEp" >
                    View details 
                  </span>
                </div>
                
                {/* EnterPrise Collapse */}
                <div className="collapse" id="collapseEp">
                  <table className="table  pricing-table" >
                    <thead>
                      <tr>
                        <th className="text-left "> <div className=" first-th"> Features </div></th>
                        <th > <div className="last-th"> Enterprise  </div> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td  className="text-left">Licensed Users</td>
                        <td className="last-cell"> Custom </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Policies Upload
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='enterprise-policiesUpload'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='enterprise-policiesUpload' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.policiesUpload}</span>
                          </ReactTooltip>
                        </td>
                          <td className="last-cell">Unlimited</td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Clients
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='enterprise-clients'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='enterprise-clients' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.clients}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell">Unlimited</td>
                      </tr>
                      <tr>
                        <td className="text-left">Quotations</td>
                        <td className="last-cell">Unlimited</td>
                      </tr>
                      <tr >
                        <td className="text-left "> 
                          Storage 
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='enterprise-fileStorage'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='enterprise-fileStorage' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.fileStorage}</span>
                          </ReactTooltip>
                        </td>
                        <td className="last-cell">Unlimited</td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Sub Agency
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='enterprise-subAgency'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='enterprise-subAgency' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.subAgency}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"> <i className="fas fa-check blue"></i> </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Employee Benefits
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='enterprise-employeeBenefits'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='enterprise-employeeBenefits' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.employeeBenefits}</span>
                          </ReactTooltip> 
                        </td>
                        <td className="last-cell"><i className="fas fa-check blue"></i></td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          Business Mobile App
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='enterprise-BusinessMobileApp'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='enterprise-BusinessMobileApp' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.BusinessMobileApp}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"><i className="fas fa-check blue"></i></td>
                      </tr>
                      <tr>
                        <td className="text-left">
                        Technical Account Management
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='enterprise-TechnicalAccountManagement'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='enterprise-TechnicalAccountManagement' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.TechnicalAccountManagement}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell"><i className="fas fa-check blue"></i></td>
                      </tr>
                      <tr>
                        <td className="text-left">
                        Priority Response
                          <button
                            type="button"
                            className="btn info-btn" data-tip data-for='enterprise-PriorityResponse'>                           
                            <i className="fa fa-info info-icon"></i>
                          </button>
                          <ReactTooltip className="tooltip-sm" id='enterprise-PriorityResponse' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                            <span>{data.PriorityResponse}</span>
                          </ReactTooltip> 
                          </td>
                        <td className="last-cell">12hrs</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>



          {/* PRICING TABLE */}
          <div className="row d-none d-md-block">
            <div className="col-12">
              <table className="table  pricing-table table-responsive-md" >
                <thead>
                  <tr>
                    <th className="text-left "> <div className=" first-th"> Features </div></th>
                    <th><div>Solo</div></th>
                    <th><div>Team</div></th>
                    <th><div>Team<i className="fa fa-plus"></i></div></th>
                    <th><div className="last-th" >Enterprise</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td  className="text-left">License Per Users</td>
                    <td>1</td>
                    <td>3</td>
                    <td>5</td>
                    <td className="last-cell"> Custom </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      Policies Upload
                      <button
                        type="button"
                        className="btn info-btn" data-tip data-for='policiesUpload'>                           
                        <i className="fa fa-info info-icon"></i>
                      </button>
                      <ReactTooltip id='policiesUpload' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                        <span>{data.policiesUpload}</span>
                      </ReactTooltip>
                      </td>
                    <td>10,000</td>
                    <td>Unlimited</td>
                    <td>Unlimited</td>
                    <td className="last-cell">Unlimited</td>
                  </tr>
                  <tr>
                    <td className="text-left"> 
                    Clients
                    <button
                        type="button"
                        className="btn info-btn" data-tip data-for='clients'>                           
                        <i className="fa fa-info info-icon"></i>
                      </button>
                      <ReactTooltip id='clients' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                        <span>{data.clients}</span>
                      </ReactTooltip> 
                    </td>
                    <td>1000</td>
                    <td>Unlimited</td>
                    <td>Unlimited</td>
                    <td className="last-cell">Unlimited</td>
                  </tr>
                  <tr>
                    <td className="text-left">Quotations</td>
                    <td>Unlimited</td>
                    <td>Unlimited</td>
                    <td>Unlimited</td>
                    <td className="last-cell">Unlimited</td>
                  </tr>
                  <tr >
                    <td className="text-left "> 
                       File Storage 
                      <button
                        type="button"
                        className="btn info-btn" data-tip data-for='fileStorage'>                           
                        <i className="fa fa-info info-icon"></i>
                      </button>
                      <ReactTooltip id='fileStorage' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                        <span>{data.fileStorage}</span>
                      </ReactTooltip>
                    </td>
                    <td>10 Gb</td>
                    <td>Unlimited</td>
                    <td>Unlimited</td>
                    <td className="last-cell">Unlimited</td>
                  </tr>
                  <tr>
                    <td className="text-left"> 
                    Sub-Agency
                    <button
                        type="button"
                        className="btn info-btn" data-tip data-for='subAgency'>                           
                        <i className="fa fa-info info-icon"></i>
                      </button>
                      <ReactTooltip id='subAgency' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                        <span>{data.subAgency}</span>
                      </ReactTooltip> 
                    </td>
                    <td> -  </td>
                    <td> <i className="fas fa-check blue"></i> </td>
                    <td> <i className="fas fa-check blue"></i> </td>
                    <td className="last-cell"> <i className="fas fa-check blue"></i> </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                       Employee Benefits
                       <button
                        type="button"
                        className="btn info-btn" data-tip data-for='employeeBenefits'>                           
                        <i className="fa fa-info info-icon"></i>
                      </button>
                      <ReactTooltip id='employeeBenefits' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                        <span>{data.employeeBenefits}</span>
                      </ReactTooltip> 
                       </td>
                    <td> -  </td>
                    <td> - </td>
                    <td> <i className="fas fa-check blue"></i> </td>
                    <td className="last-cell"> <i className="fas fa-check blue"></i> </td>
                  </tr>
                  <tr>
                    <td className="text-left"> 
                    Business Mobile App
                    <button
                        type="button"
                        className="btn info-btn" data-tip data-for='BusinessMobileApp'>                           
                        <i className="fa fa-info info-icon"></i>
                      </button>
                      <ReactTooltip id='BusinessMobileApp' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                        <span>{data.BusinessMobileApp}</span>
                      </ReactTooltip> 
                    </td>
                    <td> <i className="fas fa-check blue"></i>  </td>
                    <td> <i className="fas fa-check blue"></i> </td>
                    <td> <i className="fas fa-check blue"></i> </td>
                    <td className="last-cell"> <i className="fas fa-check blue"></i> </td>
                  </tr>
                  <tr>
                    <td className="text-left"> 
                    Technical Account Management
                    <button
                        type="button"
                        className="btn info-btn" data-tip data-for='TechnicalAccountManagement'>                           
                        <i className="fa fa-info info-icon"></i>
                      </button>
                      <ReactTooltip id='TechnicalAccountManagement' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                        <span>{data.TechnicalAccountManagement}</span>
                      </ReactTooltip> 
                    </td>
                    <td> -  </td>
                    <td> - </td>
                    <td> <i className="fas fa-check blue"></i> </td>
                    <td className="last-cell"> <i className="fas fa-check blue"></i> </td>
                  </tr>
                  <tr>
                    <td className="text-left"> 
                    Priority Response
                    <button
                        type="button"
                        className="btn info-btn" data-tip data-for='PriorityResponse'>                           
                        <i className="fa fa-info info-icon"></i>
                      </button>
                      <ReactTooltip id='PriorityResponse' place='top' type='light'  effect='solid' border borderColor='lightgray'>
                        <span>{data.PriorityResponse}</span>
                      </ReactTooltip> 
                    </td>
                    <td> 48hrs </td> 
                    <td> 48hrs </td>
                    <td> 24hrs </td> 
                    <td className="last-cell"> 12hrs </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>  
        </div>
      </section>




      {/* //FAQ */}
      {FAQData.length > 0 && (
        <section className="section-white-pricing" id="faq">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center padding-bottom-20">
                <h2 className="section-title-pricing">Frequently Asked Question(FAQ)</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12  padding-20">
                {FAQData.map( f =>{
                  return(
                    <div data-toggle="collapse" className="FAQ-collapse " key={f.ID} data-target={`#${f.Question.replace( /\s/g, "-").replace("?","-")}${f.ID}`} aria-expanded="false" aria-controls="true">
                      <div className="row" >
                        <div className="col-md-11 col-10  d-flex align-items-center" > 
                          <h6 className="mb-0"> {f.Question}</h6>
                        </div>
                        <div className="col-md-1 col-2 " >
                          <i className="fa fa-plus"></i>
                        </div>
                      </div>
                      <div className="collapse mt-3" id={`${f.Question.replace( /\s/g, "-").replace("?","-")}${f.ID}`}>
                        {f.Answer}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      )}   
      {showContactModal && (
        <ContactModal 
          show={showContactModal}
          onDismissModal={()=> setShowContactModal(false)}
        />
      )} 
    </div>
  )
}

export default Pricing;


