import React,{useEffect}  from "react";
import Top                from "../../assets/images/Top.png";
import secureEasyImg      from "../../assets/images/secure_easy.png";
import { appUrl, scrollPage }     from "../global/Helper";

const HomeSection = () => {
  
  useEffect(()=>{
    scrollPage()
  },[])

  return (
    <>
    <section className="home-section home-section-blue" id="home">
      <div className="container">
        <div className="row">
          <div className="col-md-6 padding-top-80">
            <h1 className="head-1">
              Insurance Agency Management Software
            </h1>
            <p className="head-1">
              Insurebox software is a smart way to manage your insurance
              agency. Our software will help you manage your insurance
              agency business more efficiently and help you improve your 
              business.
            </p>
            <div className="mb-2">
              <span
                className="btn-signup-menu"
                id="menuSignUp"
                onClick={appUrl}>     
                Get Started For Free
              </span>
            </div>
            <small className="text-white pl-1">No Credit Card Required</small>
          </div>
          <div className="col-md-6">
            <img src={Top} className="hero-image width-100" alt="pic" />
          </div>
        </div>
      </div>
    </section>
    <section className="section-white margin-t10">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center padding-bottom-10">
            <h2 className="section-title">Our Management System is Safe, Secure, Easy To Use</h2>
            <div className="colored-line"></div>
          </div>
        </div>
        <div className="row justify-content-center  padding-sm-lr20">
            <img src={secureEasyImg} />
        </div>
      </div>
    </section>
    </>
  );
};
export default HomeSection;
