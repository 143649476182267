import React        from "react";
import ReactDOM     from "react-dom";
import App          from "./containers/App";
import axios        from "axios";

axios.defaults.baseURL =
  window.location.hostname === "localhost"
    ? "http://localhost:8080"
    : `${window.location.protocol}//admin.insurebox.co`; //window.location.indexOf('localhost') >=0 ? 'http://localhost:8080' : window.location.host //'http://api.insurebox.co';

axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

ReactDOM.render(<App />, document.getElementById("root"));
