import React,{useEffect}                        from "react";
import Helmet from "react-helmet";
import { scrollPage, unHightlightNavlink  }     from "../global/Helper";


const RefundPolicy = () => {

  useEffect(()=>{
   scrollPage();
   unHightlightNavlink();
  },[])

    return (
        <>
        <Helmet >
            <title>Refund Policy</title>
            <meta name="description" content="InsureBox  is committed to your satisfaction. If you have purchased subscription from Insurebox.co and are unhappy with the product received, you may be eligible for a refund / partial refund if requested within 14 days of the original purchase date." />
        </Helmet>
        <section className="policy-section"  onScroll={() => unHightlightNavlink()}>
            <div className="container">
                <div className="section-header padding-0 wow fadeIn animated" data-wow-offset="120" data-wow-duration="1.5s">
                    <h2 className="dark-text">Refund Policy</h2>
                </div>
                <div className="row padding-15">
                    <div className="col-xs-12 text-left">
                        <div className="colored-line-privacy"></div>
                        <div className="text-center">
                            <div className="section-description">1. Introduction</div>
                        </div>
                        <div className="colored-line-privacy"></div>
                        <br/>

                        <p><strong>"InsureBox "</strong> is committed to your satisfaction. If you have purchased <strong>subscription</strong> from  
                        <strong> Insurebox.co</strong> and are unhappy with the product received, you may be eligible for a <strong>refund / partial refund</strong> if requested within <strong>14</strong> days of the original purchase date.</p>

                        <br/>
                        <div className="colored-line-privacy"></div>
                        <div className="text-center">
                            <div className="section-description">2. Refunds of Digital / Subscription Based Goods</div>
                        </div>
                        <div className="colored-line-privacy"></div>
                        <br/>


                        <p>To be eligible for a refund on any digital/subscription based goods, the following steps must be taken:</p>
                        <ul>
                            <li>1. Refund must be requested in writing by contacting <strong>support@insurebox.co</strong></li> 
                            <li>2. Request of refund must be made within <strong>14</strong> days of the original purchase date</li>
                        </ul>

                        <p>
                        <strong>InsureBox</strong>   is committed to its consumers, and while we stand by our policy as written above, we also want to understand how we can resolve the dissatisfaction and better understand how we can serve you. Please contact  us at <strong>support@insurebox.co</strong> for any questions related to our policy, or simply to let us know how we can help. 
                        </p>

                        <br/>
                    
                    </div>  
                </div>
            </div>
        
        </section>
        </>
    );
};
export default RefundPolicy;
