import React            from "react";
import $                from "jquery";
import { Link }         from "react-router-dom";
import { useHistory }   from "react-router";
import { scrollPage }   from "../global/Helper";
import ibLogo           from "../../assets/images/img_logo_all.png";

const Footer = (props) => {
  const history = useHistory();

  const scrollToId=()=>{
   const element = document.getElementById('features');
    if(element){
     element.scrollIntoView({behavior: "smooth"});
    }else{ 
      history.push("/");
      setTimeout(()=>{
        $("#menuFeatures").trigger("click");
      },200)
    }
  }

  return (
    <>
      {/* // <!--begin footer --> */}
      <div className="footer">
        <div className="container">
          <div className="row padding-t20">
            <div className="col-lg-2 col-md-4 col-6 margin-tb10">
              <h5 className="footer-section-title">InsureBox</h5>
              <ul className="footer-section-list">
                <li className="">
                  <span onClick={scrollToId}>Features</span>
                </li>
                <li className="">
                  <Link to="/blogs/all" onClick={scrollPage} >Blogs</Link>
                </li>                  
                <li className="">
                  <Link to="/pricing" onClick={scrollPage} >Pricing</Link>
                </li>
                <li className="">
                 <Link to="/about" onClick={scrollPage} >About</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-4 col-6 margin-tb10">
              <h5 className="footer-section-title">Support</h5>
              <ul className="footer-section-list">
                <li className="">
                  <Link to="/knowledgebase/all" onClick={scrollPage} > Knowledge Base </Link>
                </li> 
                <li className="">
                  <Link to="/video-tutorial/all" onClick={scrollPage} > Video Tutorials </Link>
                </li> 
                <li className="">
                  <span className="" onClick={()=>{props.modalHandler('contactUs')}} > Get in touch </span>
                </li>              
                <li className="">
                  <span className="" onClick={()=>{props.modalHandler('support')}} > Support </span>
                </li>              
              </ul>
            </div>           
          
                  
          
            <div className="col-lg-2 col-md-4 col-6 margin-tb10">
              <h5 className="footer-section-title">Legal</h5>
              <ul className="footer-section-list">
                <li className="">
                  <Link to="/privacy-policy" > Privacy </Link>
                </li>
                <li className="">
                  <Link to="/terms-condition" > Terms & Conditions</Link>
                </li>
                <li className="">
                  <Link to="/refund-policy" > Refunds</Link>
                </li>               
              </ul>
            </div>

            <div className="col-lg-3 col-md-4 col-6 margin-tb10">
              <h5 className="footer-section-title">Download</h5>
              <ul className="footer-section-list">
                <li className="">
                  <a href="https://play.google.com/store/apps/details?id=com.insurebox.ibbusiness" target='_blank' rel="noopener noreferrer">Android (Insurebox Business) </a>
                </li>
                <li className="">
                  <span className=""> iOS {`(Coming Shortly)`}</span>
                </li>         
              </ul>
            </div>            
          
            <div className="col-lg-3 col-md-4 col-12 margin-tb10">
              <img src={ibLogo} className="padding-b15" height="40"  alt="ib-logo-black" />
              <ul className="footer_social">
                <li>
                  <a
                    href="https://www.facebook.com/insurebox"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/insurebox/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/insurebox"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
               
                <li>
                  <a
                    href="https://wa.me/+919221008090?text=Hi,%20I%20want%20to%20know%20more%20about%20InsureBox"
                    target="_blank"
                    rel="noopener noreferrer">
                   <i className="fab fa-whatsapp"></i>
                  </a>
                </li>
              </ul>

              <p className="contact-info mb-0">
                <span> team@insurebox.co </span><br/>
                <span> +91 9221008090 </span>
              </p>
              <p className="contact-info mt-0 mb-0">
                <span>
                  Amasan InsurTech Pvt Ltd.<br /> 
                  1901, B-wing, Kailas Business Park,<br /> 
                  Vikhroli West, Mumbai - 400 079 </span>
              </p>
            </div>            
          
          </div>
        </div>
        <hr />
        <div className="container margin-b10">
          <div className="row">
            <div className="col-12 label-copyright">
              <span className="margin-b20">
                ©2023 InsureBox, All Rights Reserved
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <ContactUsModal /> */}
    </>
  );
};

export default Footer;
