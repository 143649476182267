import React, {useState, useEffect} from "react";
import {highlightNavlink}           from "../global/Helper";
import Spinner                      from "../global/Spinner";
import { Helmet }                   from "react-helmet";

const BookDemo = () => {
    
  const[loader, setLoader] = useState(true);

  useEffect(() => {
    highlightNavlink('menuBookDemo');
  }, [])

  useEffect(() => {
    const cal =  document.getElementById('calendly');
    cal.style.display = "none";
    cal.addEventListener('load',() => {
      setLoader(false);
      cal.style.display = "block";
    })
  }, [])

  return(<>

    <section className="home-section pb-3 padding-t100" >
      <Helmet>
        <title> Request For Demo | Insurebox.co </title>
        <meta name="description" content="This demo will showcase the features & benefits of using InsureBox and how you can manage your Insurance Agency Business more efficiently while saving cost and increasing revenue."/>
      </Helmet>
      <div className="container-case primary-content-container blog-post-list pb-0 calendly-container " >
        {loader && <Spinner />} 
        <iframe
          id="calendly"
          src="https://calendly.com/insurebox/demo"
          width="100%"
          height="646px"
          frameBorder="0">
        </iframe>
      </div>
    </section>
  </>)
}

export default BookDemo;


