import React                        from "react";
import quickpolicy                  from "../../assets/images/Quick_Policy_Upload.png";
import clientmanagement             from "../../assets/images/Client_Management.png";
import policystatus                 from "../../assets/images/Policy_Status.png";
import quotation                    from "../../assets/images/Quotation.png";
import endorsement                  from "../../assets/images/Endorsement.png";
import filemanagement               from "../../assets/images/File_Management.png";
import setremeinders                from "../../assets/images/Set_reminders.png";
import reports                      from "../../assets/images/Reports.png";
import team                         from "../../assets/images/Team.png";
import { appUrl }                   from "../global/Helper";

const FeaturesSection = () => {

  const featureBoxStyle = {
    visibility: "visible",
    animationDelay: "0.25s",
    animationName: "fadeIn",
  };

  return (
    <section
      className="section-grey section-top-border padding-b20"
      id="features">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center padding-bottom-10">
            <h2 className="section-title">Insurebox Insurance Software Modules</h2>
            <div className="colored-line"></div>
            <p>Key Benefits - Management System</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={quickpolicy} className="img-features" alt=" " />
              <h4>Quick Insurance Policy Upload</h4>
              <p>
                Upload policies easily with drag and drop.
                Our insurance app will automatically take
                the relevant information saving a lot of time 
                         for data entry.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={clientmanagement} className="img-features" alt=" " />
              <h4>Client Management</h4>
              <p>
                Using our management system,you can 
                easily add corporate and individual clients
                and retrieve their insurance policies with just
                            a tap.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={policystatus} className="img-features" alt=" " />
              <h4>Policy Status</h4>
              <p>Get updated on the upcoming renewal , Status of policies.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={quotation} className="img-features" alt=" " />
              <h4>Quotations </h4>
              <p>
                Add quotes and send instantly from the app and track the status
                of quotation & conversions.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={endorsement} className="img-features" alt=" " />
              <h4>Endorsement </h4>
              <p>
                Create endorsement and send directly to insurance companies
                executives and track the status.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={filemanagement} className="img-features" alt=" " />
              <h4>File Management</h4>
              <p>
                Add brochure , claims forms and other frequently used documents
                and retrieve and send to clients instantly.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={setremeinders} className="img-features" alt=" " />
              <h4>Tasks</h4>
              <p>Easily set tasks for yourself and your team.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>
              <img src={reports} className="img-features" alt=" " />
              <h4>Reports</h4>
              <p>Generate Business & Revenue reports instantly.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 benefitsBlock">
            <div
              className="feature-box  fadeIn"
              data-wow-delay="0.25s"
              style={featureBoxStyle}>     
              <img src={team} className="img-features" alt=" " />
              <h4>Team </h4>
              <p>Add and manage team and view their progress.</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center padding-t15">
          <span
            className="btn-signup color-white text-center"
            onClick={appUrl}
            >
            <span>Start Free Trial</span>
          </span>
        </div>
      </div>
    </section>
  );
};
export default FeaturesSection;
