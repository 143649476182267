import React                   from "react";
import $                       from "jquery";
import ibLogo                  from "../../assets/images/img_logo_all.png";
import { Link, useHistory }    from "react-router-dom";
import {  scrollPage }         from "./Helper";
import { appUrl }              from "./Helper";


const Header = (props) => {
  
  const history = useHistory();

  const scrollToId = () => {
    const element = document.getElementById('features');
    if(!element){ 
      history.push("/");
      setTimeout(()=>{
        document.getElementById('features').scrollIntoView({behavior: "smooth"});
      },200);
    }
  }

  const pushResource = (key) => {
    history.push(`/${key}`);
    scrollPage();
    if($(window).width() < 960)  $("#navbar-toggler").trigger("click");
  };

  const scrollHandler = () => {
    $("#ul-list").children("li").removeClass('current');
    const element = document.getElementById('home')
    if (element) {
      document.getElementById('home').scrollIntoView({behavior: "smooth"});
    }else{
      history.push("/");
    }
  }

  

  
  return (
    <header className="header">
      <nav id="nav-ib" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <Link to="/" onClick={() => scrollHandler()} className="navbar-brand">
              <img id="imgLogo" src={ibLogo} alt="ib-logo" />
            </Link>
            <button
              id="navbar-toggler"
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">
                <i className="fas fa-bars"></i>
              </span>
            </button>
            <div className="navbar-collapse collapse" id="navbarCollapse">
              <ul id="ul-list" className="navbar-nav ml-auto">
                <li className="d-none">
                  <a href="#home" onClick={()=> history.push("/")} id="menuHome">Home</a>
                </li>
                <li>
                  <a href="#features" id="menuFeatures" onClick={scrollToId}> Features</a>
                </li>
                <li>
                  <span id="menuPricing" onClick={() => {pushResource("pricing")}} > Pricing </span>
                </li>
                <li>
                  <span onClick={() => pushResource("bookdemo")}  className=" d-block d-lg-none  bookDemo" >  Book Demo</span>
                </li>
                <li id="menuResources" className=" dropdown d-none d-lg-block">
                  <span
                    className="dropdown-toggle"
                    data-toggle="dropdown">
                    Resources
                  </span>
                  <ul className="dropdown-menu sub-item">
                    <li>
                      <span
                        className="dropdown-item"
                        id="knowledgeBase"
                        onClick={() => pushResource("knowledgebase/all")}>
                        Knowledge Base
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        id="menuVideo"
                        onClick={() => pushResource("video-tutorial/all")}>
                        Video Tutorial
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        id="support"
                        onClick={()=> props.modalHandler('support')}>
                        Support
                      </span>
                    </li>
                    <li id="menuBlogList">
                      <span
                        className="dropdown-item"
                        onClick={() => {pushResource("blogs/all"); }}>
                        Blog
                      </span>
                    </li>
                  </ul>
                </li>
                <li className="collapse d-block d-lg-none">
                  <button
                    className="res-icon collapsed"
                    data-toggle="collapse"
                    data-target="#menuResource"
                    aria-controls="menuResource"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="nav-item mr-1">Resources</span>
                  </button>
                  <div
                    className="navbar-collapse collapse"
                    id="menuResource">
                    <ul className="navbar-nav ml-auto  sub-item">
                      <li>
                        <span
                          className="sub-menu"
                          id="knowledgeBase1"
                          onClick={() => pushResource("knowledgebase/all")}>
                          Knowledge Base
                        </span>
                      </li>
                      <li>
                        <span
                          className="sub-menu"
                          id="menuVideo1"
                          onClick={() => pushResource("video-tutorial/all")}>
                          Video Tutorial
                        </span>
                      </li>
                      <li>
                        <span
                          className="sub-menu"
                          id="menuBlog1"
                          onClick={() => pushResource("blogs/all")}>
                          Blog
                        </span>
                      </li>
                      <li>
                        <span
                          id="support1">
                          <span
                            data-toggle="modal"
                            data-target="#modalSupport"
                            className="sub-menu"
                            onClick={()=> props.modalHandler('support')}>
                            Support
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </li>
                
                <li id="menuLogin">
                  <span
                    onClick={() => window.location.href = "https://app.insurebox.co/signin" }>
                    Login
                  </span>
                  <span
                    className="sign-up-free"
                    onClick={appUrl}>
                    <span className="btn-signup-menu  text-center">
                      Start Free Trial
                    </span>
                  </span> 
                </li>
                <li>
                  <span id="menuBookDemo"  onClick={() => pushResource("bookdemo")}
                    className=" d-none d-lg-block bookDemo" >  Request Demo </span>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </nav>
  
    </header>
  );
};

export default Header;
