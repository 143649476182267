import React, {useState, useEffect}                           from "react";
import $                                                      from "jquery";
import moment                                                 from "moment";
import Swal                                                   from "sweetalert2";
import noblog                                                 from "../../assets/images/no_blog.png";
import userImg                                                from "../../assets/images/user.png";
import { getLogo }                                            from "../global/Helper";
import {NavLink, useRouteMatch, useHistory}                   from "react-router-dom";
import {highlightResource,capitalizeFirstLetter,scrollPage}   from "../global/Helper";
import {getBlogList,getAuthorBacklinks}                       from "../api/webAPI";
import { Helmet }                                             from "react-helmet";

const Blogs = () => {

	const [blogs,                           setBlogs]           =     useState([]);
	const [filteredBlogs,                   setfilteredBlogs]   =     useState([]);
	const [blogTags,                        setBlogTags]        =     useState([]);
	const [selectedBlog,                    setSelectedBlog]    =     useState(null);
	const [authorBacklinks,                 setAuthorBacklinks] =     useState([]);
	const match                                                 =     useRouteMatch();
	const history                                               =     useHistory();
	const urlParam                                              =     match.params.tag; 

  	const filterBlogs = () => {
		if(urlParam === "all"){
			setfilteredBlogs(blogs);
		}else{
			const checkInBlogTags = blogTags.find(t => t.TagName.replace(/\s/g, "-").toLowerCase() === urlParam);
			const indexInBlogs    = blogs.findIndex(b => b.Alias === urlParam);

			if(!checkInBlogTags && (indexInBlogs === -1) ) return history.push("/blogs/all");

			if(indexInBlogs !== -1){
			
				setSelectedBlog(blogs[indexInBlogs]);
				if(indexInBlogs === 0){
					return setTimeout(() => {
						$('#prevBtn').hide();
					}, 50)
				}
				if(indexInBlogs === (blogs.length -1) ){
					return setTimeout(() => {
						$('#nextBtn').hide();
					}, 50)
				}
				return;
			};

			if( blogs.length > 0 )
			setfilteredBlogs(blogs.filter(
				b => blogTags.find(t => t.TagName.replace(/\s/g, "-").toLowerCase() === urlParam)['BlogID'].includes(b.ID)
			));
		}
  	}

	useEffect(() => {
		let ignore = false;
		const fetchAuthorBacklinks = async () => {
			try {
				if(selectedBlog && selectedBlog.AuthorID){
					const response = await getAuthorBacklinks(selectedBlog.AuthorID);
					if(response.length > 0 ) setAuthorBacklinks(response);
				}
			} catch (error) {
				Swal.fire({
					icon                : "error",
					titleText           : "Error!",
					text                : error.message,
					buttonsStyling      : false,
					confirmButtonClass  : "btn btn-brand",
				});
			}
		}
		if(!ignore) fetchAuthorBacklinks();
		return() => {
			ignore = true;
		}
	},[selectedBlog]);
  

  	// Fetch All Active Blogs
	useEffect(() => {
		let ignore = false;
		const fetchBlogs = async () => {
			try {
				if(blogs.length === 0 ){
					const response = await getBlogList();
					if(response && response.blogList.length > 0 ) setBlogs(response.blogList);

					if(response && response.blogTags.length > 0 ){
						setBlogTags(response.blogTags);
					}else{ 
						setfilteredBlogs(response.blogList);
					}
				}
			} catch (error) {
				Swal.fire({
				icon                : "error",
				titleText           : "Error!",
				text                : error.message,
				buttonsStyling      : false,
				confirmButtonClass  : "btn btn-brand",
				});
			}
		}
		if(!ignore){
			highlightResource();
			fetchBlogs();
		}
		return() => { 
			ignore = true;
		}
	}, [])

	// Filter All Blogs asper Url Tag
	useEffect(() => {
		const setPage = () => {

			if(blogTags.length > 0 ) return filterBlogs();
			if( (blogs.length > 0) && blogs.find(b => b.Alias === urlParam)) return filterBlogs();
		}
		setPage();
	},[urlParam, blogs])

	useEffect(() => {
		if(blogTags.length > 0 )filterBlogs();
	},[blogTags])

	useEffect(() => {
		$('.nav-item').removeClass('active');
		$(`#${urlParam}`).addClass("active");
	},[filteredBlogs])

	const pushtoBlog = (alias) => {
		history.push(`/blogs/${alias}`)
		scrollPage();
	}
	
	const nextHandler = (id) => {
		const currentBlogIndex = blogs.findIndex( k => k.ID === id );
		if( currentBlogIndex !== (blogs.length -1)) pushtoBlog(blogs[currentBlogIndex +1].Alias);
		$('#prevBtn').show();
	}

	const prevHandler = (id) => {
		const currentBlogIndex = blogs.findIndex( k => k.ID === id );
		if( currentBlogIndex !== 0 ) pushtoBlog(blogs[currentBlogIndex -1].Alias);
		$('#nextBtn').show();
	}

  	return (
		<>
			<Helmet> 
				<title> Best Insurance Blog For Insurance Agents | Insurebox.co </title> 
			</Helmet>
			<div onScroll={() => highlightResource()}> 
				{selectedBlog ? 
					<section className="home-section" id="blogs">
						<div className="container-case primary-content-container blog-post-list">
							<div className="row-case">
								<div className="col-xs-12-c">
									<div className="blog-post row-case">
										<div className="col-xs-12-c col-sm-10-c col-sm-offset-1-c col-md-10-c col-md-offset-1-c col-lg-8-c col-lg-offset-2-c">
											<div className="blog-post-nav-links">
												<NavLink className="u-tC-gray back-to-blog" to="/blogs/all"  onClick={() => setSelectedBlog(null)}>
													<i className="fas fa-arrow-left" style={{marginRight: "10px"}}></i>
													Back to Blogs
												</NavLink>
											</div>
											<div className="partnership-card blog-post-container mb-0">
												<div className="d-flex justify-content-center">
													{selectedBlog.BannerImage && <img className="blog-post-image" src={selectedBlog.BannerImage} alt="BannerImage" />}
												</div>
												
												<div className="blog-post-body  padding-t10">
													<div className="blog-post-details"></div>
													<h1 className="blog-post-title mb-3">{selectedBlog.Title}</h1>
													<div className="row margin-b20">
														<div className="col-12">
															<span className="blog-icons last author-container"  target="_blank">
																<div className="authorImage-container mr-2">
																	<img src={selectedBlog.Image ? selectedBlog.Image :userImg } alt="Author Image" className="img-fluid" />
																</div>
																<div>
																	<span className="author-title">
																		{`${selectedBlog.Name}`}{selectedBlog.Designation && ` | ${selectedBlog.Designation}`}
																	</span>
																	<br />
																	<span>
																		{moment(selectedBlog.Date).format("MMMM Do YYYY")}
																	</span>
																</div>
																{authorBacklinks && authorBacklinks.length > 0 && (
																	<div className="margin-l25">
																		{authorBacklinks.map((b,i)=> {
																			return(
																				<a href={b.Url} target="_blank" key={i}>
																					<img className="img-fluid blog-backlink-logo" src={getLogo(b.Type)} ></img>
																				</a> 
																			);
																		})}
																	</div>
																)}                               
															</span>                                                                 
														</div>       
													</div>
													<div className="blog-post-content">
														{selectedBlog.Description}
													</div>
													<br/>
													<div className="blog-post-content" dangerouslySetInnerHTML={{__html:selectedBlog.Content}}></div>
												</div>
											</div>
											<div className="row ml-0" style={{width:'100%'}}>
												<div className="col-6">
													<span id="prevBtn" className="u-tC-gray back-to-blog clickable" onClick={() => prevHandler(selectedBlog.ID)} >
														<i className="fas fa-arrow-left" style={{marginRight: "10px"}}></i>
														Prev
													</span>
												</div>
												<div className="col-6 text-right">
													<span id="nextBtn" className="u-tC-gray back-to-blog clickable" onClick={() => nextHandler(selectedBlog.ID)}>
														Next 
														<i className="fas fa-arrow-right ml-2" ></i>
													</span>
												</div>
											</div>
										</div>
									</div>       
								</div>
							</div>
						</div>
					</section>
					:
					<section className="section-white section-top-border">
						<div className="blog-container">
							{filteredBlogs.length> 0 ? 
								<>
									<div className="postCats__wrap">
										<ul className="nav postCats">
											<li className="nav-item active" id="all">
												<NavLink className="nav-link" to="/blogs/all">All</NavLink>
											</li>
											{blogTags.length > 0 && blogTags.map((t, i) => {
												return(
													<li className="nav-item" id={`${t.TagName.replace(/\s/g, "-").toLowerCase()}`} key={i}>
														<NavLink className="nav-link" to={`/blogs/${t.TagName.replace(/\s/g, "-").toLowerCase()}`}>
															{t.TagName && capitalizeFirstLetter(t.TagName) }
														</NavLink>
													</li>
												);
											})}
										</ul>
									</div>
									<div className="row">
										{filteredBlogs.map((b, i) => {
											return(
												<div className="col-xl-4 col-md-6" key ={i}>
													<div className="blog-item">
														<div className="popup-wrapper">
															<div className="popup-gallery">
																<NavLink to={`/blogs/${b.Alias}`}>
																	<img className="width-100" src={b.BannerImage}/>
																	<span className="eye-wrapper2">
																		<i className="fa fa-link eye-icon"></i>
																	</span>
																</NavLink>
															</div>
														</div>
														<div className="blog-item-inner">
															<h3 className="blog-title">
																<span 
																	className="clickable" 
																	onClick={() => pushtoBlog(b.Alias)}>
																	{b.Title} 
																</span>
															</h3>
															<span className="blog-icons last ">
																<i className="fa fa-clock-o mr-1"></i>
																{moment(b.Date).format("MMMM Do YYYY")}
															</span>
															{b.Description && 
																<p>
																	{b.Description
																		.split(" ")
																		.splice(0, 15)
																		.join(" ") 
																	}
																	<span 
																		className="clickable" 
																		onClick={() => pushtoBlog(b.Alias)}  
																		style={{color: "#00ab66"}} href="#">
																		<strong>...Read More</strong>
																	</span>
																</p>
															}
														</div>
													</div>
												</div>
											)
										})}       
									</div> 
								</>
								: 
								<div className="row">
									<div className="col-sm-12 text-center padding-10">
										<img src={noblog} alt="no-blog" />
										<h4 className="img-empty">Sorry ! No Blogs</h4>
									</div>
								</div>
							}
						</div>
					</section>
				}
			</div>
		</>
	);
};
export default Blogs;
