import React              from "react";
import policystats        from "../../assets/images/Policy Stats-800X500.png";
import clients            from "../../assets/images/Client-800X500.png";
import quotation          from "../../assets/images/Quotation-800X500.png";
import business           from "../../assets/images/Business-800X500.png";
import team               from "../../assets/images/Team-800X500.png";
import mobileapp          from "../../assets/images/MobileApp-800X500.png";

const ReportSection = () => {
  return (
    <React.Fragment>
      <section className="section-white section-top-border d-none d-lg-block">
        <div className="container">
          <div className="row">
            <div
              className=" col-md-4 nav flex-column nav-pills"
              id=""
              role="tablist"
              aria-orientation="vertical">
              <a
                className="nav-link active"
                id="v-pills-home-tab"
                data-toggle="pill"
                href="#menu1"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true">
                <div className="features-second nav-item">
                  <h4 className="margin-bottom-5">Policy Stats</h4>
                  <p>Easily upload and retrieve policies instantly.</p>
                </div>
              </a>
              <a
                className="nav-link"
                id="v-pills-messages-tab"
                data-toggle="pill"
                href="#menu2"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false">
                <div className="features-second nav-item">
                  <h4 className="margin-bottom-5">Client Management</h4>
                  <p>
                    Manage client easily and send policies to client with just a
                    tap.{" "}
                  </p>
                </div>
              </a>
              <a
                className="nav-link"
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="#menu3"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false">
                <div className="features-second nav-item">
                  <h4 className="margin-bottom-5">Quotation </h4>
                  <p>
                    Create quotation and send to clients directly from
                    InsureBox.
                  </p>
                </div>
              </a>

              <a
                className="nav-link"
                id="v-pills-settings-tab"
                data-toggle="pill"
                href="#menu4"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false">
                <div className="features-second nav-item">
                  <h4 className="margin-bottom-5">Business & Revenue Report</h4>
                  <p>Instantly generate Business & Revenue report.</p>
                </div>
              </a>

              <a
                className="nav-link"
                id="v-pills-p-tab"
                data-toggle="pill"
                href="#menu5"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false">
                <div className="features-second nav-item">
                  <h4 className="margin-bottom-5">Team Management</h4>
                  <p>
                    Add team members and collaborate and sync with them
                    efficiently.
                  </p>
                </div>
              </a>
              <a
                className="nav-link"
                id="v-pills-s-tab"
                data-toggle="pill"
                href="#menu6"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false">
                <div className="features-second nav-item">
                  <h4 className="margin-bottom-5">Mobile App</h4>
                  <p>Available both for your agency and for your clients.</p>
                </div>
              </a>
            </div>

            <div
              className="col-md-8 wow slideInRight tab-content"
              data-wow-delay="0.25s"
              style={{
                visibility: "visible",
                animationDelay: "0.25s ",
                animationName: "slideInRight",
              }}>
              <img
                className="width-100 box-shadow tab-pane fade show active"
                src={policystats}
                id="menu1"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
                alt="pic"
              />
              <img
                className="width-100 box-shadow tab-pane"
                src={clients}
                id="menu2"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                alt="pic"
              />
              <img
                className="width-100 box-shadow tab-pane "
                src={quotation}
                id="menu3"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
                alt="pic"
              />
              <img
                className="width-100 box-shadow tab-pane "
                src={business}
                id="menu4"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
                alt="pic"
              />
              <img
                className="width-100 box-shadow tab-pane "
                src={team}
                id="menu5"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
                alt="pic"
              />
              <img
                className="width-100 box-shadow tab-pane "
                src={mobileapp}
                id="menu6"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
                alt="pic"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-white section-top-border d-block d-lg-none">
        <div className="container">
          <div className="row">
            <div
              className=" col-xs-12 padding-10  nav flex-column nav-pills"
              id=""
              role="tablist"
              aria-orientation="vertical">
              <a
                className="nav-link active"
                id="v-pills-home-tab"
                data-toggle="pill"
                href="#menu1"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true">
                <div className="features-second nav-item">
                  <h4 className="margin-bottom-5">Policy Stats </h4>
                  <p>
                    <span>Easily upload and retrieve policies instantly</span>
                    <img
                      className="width-100 box-shadow tab-pane fade show margin-t20 active"
                      src={policystats}
                      id="menu1"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                      alt="pic"
                    />
                  </p>
                </div>
              </a>
              <a
                className="nav-link"
                id="v-pills-messages-tab"
                data-toggle="pill"
                href="#menu2"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false">
                <div className="features-second nav-item">
                  <h4 className="margin-bottom-5">Client Management</h4>
                  <p>
                    <span>
                      Manage client easily and send policies to client with just
                      a tap.
                    </span>
                    <img
                      className="width-100 box-shadow tab-pane margin-t20"
                      src={clients}
                      id="menu2"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                      alt="pic"
                    />
                  </p>
                </div>
              </a>
              <a
                className="nav-link"
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="#menu3"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false">
                <div className="features-second nav-item">
                  <h4 className="margin-bottom-5">Quotation </h4>

                  <p>
                    <span>
                      Create quotation and send to clients directly from
                      InsureBox.
                    </span>
                    <img
                      className="width-100 box-shadow tab-pane margin-t20"
                      src={quotation}
                      id="menu3"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                      alt="pic"
                    />
                  </p>
                </div>
              </a>

              <a
                className="nav-link"
                id="v-pills-settings-tab"
                data-toggle="pill"
                href="#menu4"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false">
                <div className="features-second nav-item">
                  <h4 className="margin-bottom-5">Business & Revenue Report</h4>
                  <p>
                    <span>Instantly generate Business & Revenue report.</span>
                    <img
                      className="width-100 box-shadow tab-pane margin-t20"
                      src={business}
                      id="menu4"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                      alt="pic"
                    />
                  </p>
                </div>
              </a>

              <a
                className="nav-link"
                id="v-pills-p-tab"
                data-toggle="pill"
                href="#menu5"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false">
                <div className="features-second nav-item">
                  <h4 className="margin-bottom-5">Team Management</h4>
                  <p>
                    <span>
                      Add team members and collaborate and sync with them
                      efficiently.
                    </span>
                    <img
                      className="width-100 box-shadow tab-pane margin-t20"
                      src={team}
                      id="menu5"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                      alt="pic"
                    />
                  </p>
                </div>
              </a>
              <a
                className="nav-link"
                id="v-pills-s-tab"
                data-toggle="pill"
                href="#menu6"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false">
                <div className="features-second nav-item">
                  <h4 className="margin-bottom-5">Mobile App</h4>
                  <p>
                    <span>
                      Available both for your agency and for your clients.
                    </span>
                    <img
                      className="width-100 box-shadow tab-pane margin-t20"
                      src={mobileapp}
                      id="menu6"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                      alt="pic"
                    />
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section-white section-top-border">
        <div className="container">
          <div className="row margin-b30">
            <div className="col-md-12 text-center padding-bottom-10">
              <h2 className="section-title">Our Insurance Agent Software Provides Everything You Need</h2>
              <div className="colored-line"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <p>
                Our insurance agent software provides everything you need to run your insurance agency. Our software makes it easy for you to manage customer information, quotations, and sales. With our innovative ways of tracking your clients insurance policies and renewals, you will spend less time looking for information and more time working with clients. With this insurance software you focus on growing your business rather than maintaining and monitoring your data.
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Insurebox Insurance agency management software is an essential tool for the insurance industry who wants to have an easy way to manage their businesses. It offers an entire suite of tools to manage your agency, products and services, as well as manage all of your marketing needs from one easy-to-use platform.
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default ReportSection;
