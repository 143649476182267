import React,{useEffect}                    from "react";
import { scrollPage,unHightlightNavlink }   from "../global/Helper";
import { Helmet }                                 from "react-helmet";

const PrivacyPolicy = () => {

  useEffect(()=>{
    scrollPage()
    unHightlightNavlink()
  },[])

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Our Privacy Policy governs your visit to insurebox.co, and explains how we collect, safeguard and disclose information that results from your use of our Service."/>
      </Helmet>
      <section className="policy-section" onScroll={() => unHightlightNavlink()}>
        <div className="container">
          <div
            className="section-header padding-0 wow fadeIn animated"
            data-wow-offset="120"
            data-wow-duration="1.5s">
            <h2 className="dark-text">Privacy Policy</h2>
          </div>
          <div className="row padding-15" />
          <div className="col-xs-12 text-left" />
          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">1. Introduction:</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />

          <p>Welcome to Insurebox.</p>
          <p>
            Amasan InsurTech Pvt Ltd (“us”, “we”, or “our”) operates insurebox.co
            (hereinafter referred to as “Service”).
          </p>
          <p>
            Our Privacy Policy governs your visit to insurebox.co, and explains
            how we collect, safeguard and disclose information that results from
            your use of our Service..
          </p>
          <p>
            We use your data to provide and improve Service. By using Service, you
            agree to the collection and use of information in accordance with this
            policy. Unless otherwise defined in this Privacy Policy, the terms
            used in this Privacy Policy have the same meanings as in our Terms and
            Conditions.
          </p>
          <p>
            Our Terms and Conditions (“Terms”) govern all use of our Service and
            together with the Privacy Policy constitutes your agreement with us
            (“agreement”).
          </p>

          <br />
          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">2. Definitions:</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />

          <p>
            SERVICE means the insurebox.co website operated by Amasan Technologies
            LLP.
          </p>
          <p>
            PERSONAL DATA means data about a living individual who can be
            identified from those data (or from those and other information either
            in our possession or likely to come into our possession).
          </p>
          <p>
            USAGE DATA is data collected automatically either generated by the use
            of Service or from Service infrastructure itself (for example, the
            duration of a page visit).
          </p>
          <p>
            COOKIES are small files stored on your device (computer or mobile
            device).
          </p>
          <p>
            DATA CONTROLLER means a natural or legal person who (either alone or
            jointly or in common with other persons) determines the purposes for
            which and the manner in which any personal data are, or are to be,
            processed. For the purpose of this Privacy Policy, we are a Data
            Controller of your data
          </p>
          <p>
            DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal
            person who processes the data on behalf of the Data Controller. We may
            use the services of various Service Providers in order to process your
            data more effectively.
          </p>
          <p>
            DATA SUBJECT is any living individual who is the subject of Personal
            Data.
          </p>
          <p>
            THE USER is the individual using our Service. The User corresponds to
            the Data Subject, who is the subject of Personal Data.
          </p>

          <br />
          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">
              3. Information Collection and Use:
            </div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />

          <p>
            We collect several different types of information for various purposes
            to provide and improve our Service to you.
          </p>
          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">4. Types of Data Collected:</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />

          <p>
            <strong>Personal Data</strong>
          </p>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (“Personal Data”). Personally identifiable information
            may include, but is not limited to:
          </p>
          <ul>
            <li>i. Email address</li>
            <li>ii. First name and last name,</li>
            <li>iii.Phone number.</li>
            <li>iv. Address, Country, State, Province, ZIP/Postal code, City.</li>
            <li>v. Cookies and Usage Data.</li>
          </ul>
          <p>
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of these
            communications from us by following the unsubscribe link.
          </p>
          <p>
            <strong>Usage Data</strong>
          </p>
          <p>
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through any device
            (“Usage Data”).
          </p>
          <p>
            This Usage Data may include information such as your computer’s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that you visit, the time and date of
            your visit, the time spent on those pages, unique device identifiers
            and other diagnostic data.
          </p>
          <p>
            When you access Service with a device, this Usage Data may include
            information such as the type of device you use, your device unique ID,
            the IP address of your device, your device operating system, the type
            of Internet browser you use, unique device identifiers and other
            diagnostic data.
          </p>
          <p>
            <strong>Location Data</strong>
          </p>
          <p>
            We may use and store information about your location if you give us
            permission to do so (“Location Data”). We use this data to provide
            features of our Service, to improve and customize our Service
          </p>
          <p>
            You can enable or disable location services when you use our Service
            at any time by way of your device settings.
          </p>
          <p>
            <strong>Tracking Cookies Data</strong>
          </p>
          <p>
            We use cookies and similar tracking technologies to track the activity
            on our Service and we hold certain information.
          </p>
          <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>
          <p>Examples of Cookies we use:</p>
          <ul>
            <li>
              i. Session Cookies: We use Session Cookies to operate our Service.{" "}
            </li>
            <li>
              ii. Preference Cookies: We use Preference Cookies to remember your
              preferences and various settings.{" "}
            </li>
            <li>
              iii.Security Cookies: We use Security Cookies for security purposes.
            </li>
            <li>
              iv. Advertising Cookies: Advertising Cookies are used to serve you
              with advertisements that may be relevant to you and your interests.
            </li>
          </ul>
          <p>
            <strong>Other Data</strong>
          </p>
          <p>
            While using our Service, we may also collect the following
            information: sex, age, date of birth, place of birth, passport
            details, citizenship, registration at place of residence and actual
            address, telephone number (work, mobile), details of documents on
            education, qualification, professional training, employment
            agreements, NDA agreements, information on bonuses and compensation,
            information on marital status, family members, social security (or
            other taxpayer identification) number, office location and other data.
          </p>
          <br />
          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">5. Use of Data:</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />

          <ul>
            <li>1. to provide and maintain our Service;</li>
            <li>2. to notify you about changes to our Service</li>
            <li>
              3. to allow you to participate in interactive features of our
              Service when you choose to do so
            </li>
            <li>4. to provide customer support</li>
            <li>
              5. to gather analysis or valuable information so that we can improve
              our Service
            </li>
            <li>6. to monitor the usage of our Service</li>
            <li>7. to detect, prevent and address technical issues</li>
            <li>8. to fulfil any other purpose for which you provide it</li>
            <li>
              9. to carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for billing
              and collection
            </li>
            <li>
              10. to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc
            </li>
            <li>
              11. to provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information
            </li>
            <li>
              12. in any other way we may describe when you provide the
              information
            </li>
            <li>13. for any other purpose with your consent.</li>
          </ul>
          <br />
          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">6. Retention of Data:</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            We will retain your Personal Data only for as long as is necessary for
            the purposes set out in this Privacy Policy. We will retain and use
            your Personal Data to the extent necessary to comply with our legal
            obligations (for example, if we are required to retain your data to
            comply with applicable laws), resolve disputes, and enforce our legal
            agreements and policies.
          </p>
          <p>
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this data
            is used to strengthen the security or to improve the functionality of
            our Service, or we are legally obligated to retain this data for
            longer time periods
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">7. Transfer of Data:</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            Your information, including Personal Data, may be transferred to – and
            maintained on – computers located outside of your state, province,
            country or other governmental jurisdiction where the data protection
            laws may differ from those of your jurisdiction.
          </p>
          <p>
            If you are located outside India and choose to provide information to
            us, please note that we transfer the data, including Personal Data, to
            India and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            Amasan InsurTech Pvt Ltd will take all the steps reasonably necessary
            to ensure that your data is treated securely and in accordance with
            this Privacy Policy and no transfer of your Personal Data will take
            place to an organisation or a country unless there are adequate
            controls in place including the security of your data and other
            personal information.
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">8. Disclosure of Data:</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            We may disclose personal information that we collect, or you provide:
          </p>
          <ul>
            <li>
              1. <strong>Disclosure for Law Enforcement:</strong> Under certain
              circumstances, we may be required to disclose your Personal Data if
              required to do so by law or in response to valid requests by public
              authorities
            </li>
            <li>
              2. <strong>Business Transaction:</strong> If we or our subsidiaries
              are involved in a merger, acquisition or asset sale, your Personal
              Data may be transferred.
            </li>
            <li>
              3.{" "}
              <strong>Other cases. We may disclose your information also:</strong>
              <ul>
                <li>3.1 to our subsidiaries and affiliates;</li>
                <li>
                  3.2 to contractors, service providers, and other third parties
                  we use to support our business;
                </li>
                <li>3.3 to fulfill the purpose for which you provide it;</li>
                <li>
                  3.4 for the purpose of including your company’s logo on our
                  website;
                </li>
                <li>
                  3.5 for any other purpose disclosed by us when you provide the
                  information;
                </li>
                <li>3.6 with your consent in any other cases;</li>
                <li>
                  3.7 if we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of the Company, our
                  customers, or others.
                </li>
              </ul>
            </li>
          </ul>
          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">9. Security of Data:</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially acceptable
            means to protect your Personal Data, we cannot guarantee its absolute
            security.
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">
              10. Your Data Protection Rights Under General Data Protection
              Regulation (GDPR){" "}
            </div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />

          <p>
            If you are a resident of the European Union (EU) and European Economic
            Area (EEA), you have certain data protection rights, covered by GDPR.
          </p>
          <p>
            We aim to take reasonable steps to allow you to correct, amend,
            delete, or limit the use of your Personal Data.
          </p>
          <p>
            If you wish to be informed what Personal Data we hold about you and if
            you want it to be removed from our systems, please email us at{" "}
            <strong>support@insurebox.co</strong>
          </p>
          <p>
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <ul>
            <li>
              1. the right to access, update or to delete the information we have
              on you;
            </li>
            <li>
              2. the right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete;
            </li>
            <li>
              3. the right to object. You have the right to object to our
              processing of your Personal Data;
            </li>
            <li>
              4. the right of restriction. You have the right to request that we
              restrict the processing of your personal information;
            </li>
            <li>
              5. the right to data portability. You have the right to be provided
              with a copy of your Personal Data in a structured, machine-readable
              and commonly used format;
            </li>
            <li>
              6. the right to withdraw consent. You also have the right to
              withdraw your consent at any time where we rely on your consent to
              process your personal information;
            </li>
          </ul>
          <p>
            Please note that we may ask you to verify your identity before
            responding to such requests. Please note, we may not able to provide
            Service without some necessary data.
          </p>
          <p>
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">
              11. Your Data Protection Rights under the California Privacy
              Protection Act (CalOPPA){" "}
            </div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            CalOPPA is the first state law in the nation to require commercial
            websites and online services to post a privacy policy. The law’s reach
            stretches well beyond California to require a person or company in the
            United States (and conceivable the world) that operates websites
            collecting personally identifiable information from California
            consumers to post a conspicuous privacy policy on its website stating
            exactly the information being collected and those individuals with
            whom it is being shared, and to comply with this policy.
          </p>
          <p>According to CalOPPA we agree to the following:</p>
          <ul>
            <li>1. users can visit our site anonymously;</li>
            <li>
              2. our Privacy Policy link includes the word “Privacy”, and can
              easily be found on the home page of our website;
            </li>
            <li>
              3. users will be notified of any privacy policy changes on our
              Privacy Policy Page;
            </li>
            <li>
              4. users are able to change their personal information by emailing
              us at <strong>support@insurebox.co</strong>
            </li>
          </ul>
          <p>Our Policy on “Do Not Track” Signals:</p>
          <p>
            We honor Do Not Track signals and do not track, plant cookies, or use
            advertising when a Do Not Track browser mechanism is in place. Do Not
            Track is a preference you can set in your web browser to inform
            websites that you do not want to be tracked.
          </p>
          <p>
            You can enable or disable Do Not Track by visiting the Preferences or
            Settings page of your web browser
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">
              12. Your Data Protection Rights under the California Consumer
              Privacy Act (CCPA)
            </div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            If you are a California resident, you are entitled to learn what data
            we collect about you, ask to delete your data and not to sell (share)
            it. To exercise your data protection rights, you can make certain
            requests and ask us:
          </p>
          <p>
            <strong>
              1.What personal information we have about you. If you make this
              request, we will return to you:
            </strong>
          </p>
          <ul>
            <li>
              1. The categories of personal information we have collected about
              you.
            </li>
            <li>
              2. The categories of sources from which we collect your personal
              information.
            </li>
            <li>
              3. The business or commercial purpose for collecting or selling your
              personal information.
            </li>
            <li>
              4. The categories of third parties with whom we share personal
              information.
            </li>
            <li>
              5. The specific pieces of personal information we have collected
              about you.
            </li>
            <li>
              6. A list of categories of personal information that we have sold,
              along with the category of any other company we sold it to. If we
              have not sold your personal information, we will inform you of that
              fact.
            </li>
            <li>
              7. A list of categories of personal information that we have
              disclosed for a business purpose, along with the category of any
              other company we shared it with.
            </li>
          </ul>
          <p>
            Please note, you are entitled to ask us to provide you with this
            information up to two times in a rolling twelve-month period. When you
            make this request, the information provided may be limited to the
            personal information we collected about you in the previous 12 months.
          </p>
          <p>
            <strong>
              2.To delete your personal information. If you make this request, we
              will delete the personal information we hold about you as of the
              date of your request from our records and direct any service
              providers to do the same. In some cases, deletion may be
              accomplished through de-identification of the information. If you
              choose to delete your personal information, you may not be able to
              use certain functions that require your personal information to
              operate.
            </strong>
          </p>
          <p>
            <strong>
              3.To stop selling your personal information. We don’t sell or rent
              your personal information to any third parties for any purpose. We
              do not sell your personal information for monetary consideration.
              However, under some circumstances, a transfer of personal
              information to a third party, or within our family of companies,
              without monetary consideration may be considered a “sale” under
              California law. You are the only owner of your Personal Data and can
              request disclosure or deletion at any time.
            </strong>
          </p>
          <p>
            If you submit a request to stop selling your personal information, we
            will stop making such transfers.
          </p>
          <p>
            Please note, if you ask us to delete or stop selling your data, it may
            impact your experience with us, and you may not be able to participate
            in certain programs or membership services which require the usage of
            your personal information to function. But in no circumstances, we
            will discriminate against you for exercising your rights.
          </p>
          <p>
            To exercise your California data protection rights described above,
            please send your request(s) by email:{" "}
            <strong>support@insurebox.co</strong>
          </p>
          <p>
            Your data protection rights, described above, are covered by the CCPA,
            short for the California Consumer Privacy Act. To find out more, visit
            the official California Legislative Information website. The CCPA took
            effect on 01/01/2020.
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">13. Service Providers</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            We may employ third party companies and individuals to facilitate our
            Service (“Service Providers”), provide Service on our behalf, perform
            Service-related services or assist us in analysing how our Service is
            used.
          </p>
          <p>
            These third parties have access to your Personal Data only to perform
            these tasks on our behalf and are obligated not to disclose or use it
            for any other purpose.
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">14. Analytics</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">15. CI/CD tools</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            We may use third-party Service Providers to automate the development
            process of our Service.
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">16. Behavioral Remarketing</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            We may use remarketing services to advertise on third party websites
            to you after you visited our Service. We and our third-party vendors
            use cookies to inform, optimise and serve ads based on your past
            visits to our Service.
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">17. Payments</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            We may provide paid products and/or services within Service. In that
            case, we use third-party services for payment processing (e.g. payment
            processors).
          </p>
          <p>
            We will not store or collect your payment card details. That
            information is provided directly to our third-party payment processors
            whose use of your personal information is governed by their Privacy
            Policy. These payment processors adhere to the standards set by
            PCI-DSS as managed by the PCI Security Standards Council, which is a
            joint effort of brands like Visa, Mastercard, American Express and
            Discover. PCI-DSS requirements help ensure the secure handling of
            payment information.
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">18.Links to Other Sites</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            Our Service may contain links to other sites that are not operated by
            us. If you click a third party link, you will be directed to that
            third party’s site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the content,
            privacy policies or practices of any third party sites or services.
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">19. Children’s Privacy</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            Our Services are not intended for use by children under the age of 18
            (“Child” or “Children”).
          </p>
          <p>
            We do not knowingly collect personally identifiable information from
            Children under 18. If you become aware that a Child has provided us
            with Personal Data, please contact us. If we become aware that we have
            collected Personal Data from Children without verification of parental
            consent, we take steps to remove that information from our servers.
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">
              20. Changes to This Privacy Policy
            </div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            We may update our Privacy Policy from time to time. We will notify you
            of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update “effective
            date” at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>

          <div className="colored-line-privacy"></div>
          <div className="text-center">
            <div className="section-description">21. Contact Us</div>
          </div>
          <div className="colored-line-privacy"></div>
          <br />
          <p>
            If you have any questions about this Privacy Policy, please contact us
            by email: <strong>support@insurebox.co</strong>
          </p>
        </div>
      </section>
    </>
  );
};
export default PrivacyPolicy;
