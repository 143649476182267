import React, {useState, useEffect}                 from "react";
import Swal                                         from "sweetalert2";
import $                                            from "jquery";
import SearchPage                                   from "./SearchPage";
import noSupport                                    from "../../assets/images/no_support.png";
import {highlightResource, scrollPage}              from "../global/Helper";
import {getKnowledgeBaseList}                       from "../api/webAPI";
import {NavLink, Link, useRouteMatch, useHistory}   from "react-router-dom";
import customer                                     from "../../assets/images/call-us.png";
import mail                                         from "../../assets/images/emails.png";
import phonecall                                    from "../../assets/images/phone-call.png";
import { Helmet }                                   from "react-helmet";


const KnowledgeBaseList = () => {
 
  const [KnowledgeBase,                             setKnowledgeBase]         =   useState([]);
  const [filteredKnowledgeBase,                     setfilteredKnowledgeBase] =   useState([]);
  const [Category,                                  setCategory]              =   useState([]);
  const [selecedCategory,                           setSelecedCategory]       =   useState({});
  const [selectedKnowledgeBase,                     setSelectedKnowledgeBase] =   useState(null);
  const [isSearching,                               setIsSearching]           =   useState(false);
  const history                                                               =   useHistory();
  const match                                                                 =   useRouteMatch();
  const urlParam                                                              =   match.params.category;

  const filterKnowledgeBase = () => {
    if(urlParam === "all"){
      setfilteredKnowledgeBase(KnowledgeBase);
    }else{
      const checkInCategory      = Category.find( t => t.CategoryName.replace(/\s/g, "-").toLowerCase() === urlParam);
      const indexInKnowledgeBase = KnowledgeBase.findIndex(k => k.Alias === urlParam);
      
      if(!checkInCategory  && (indexInKnowledgeBase === -1) ) return history.push("/knowledgebase/all");
      
      if(indexInKnowledgeBase !== -1) {
        setSelectedKnowledgeBase( KnowledgeBase[indexInKnowledgeBase] );
        const filterKb =  KnowledgeBase.filter(v => v.CategoryName === KnowledgeBase[indexInKnowledgeBase].CategoryName).sort((a,b) => a.Index - b.Index );
        const indexInFilteredKB = filterKb.findIndex(k => k.Alias === urlParam);
        setfilteredKnowledgeBase(filterKb);

        //HIDE PREV BTN IF KB IS FIRST IN KB LIST AND CATEGORY IS FIRST
        if(indexInKnowledgeBase === 0  && indexInFilteredKB === 0) return setTimeout(() => $('#prevBtn').hide(), 50);
        
        //HIDE NEXT BTN IF KB IS LAST IN KB LIST AND CATEGORY IS LAST
        if((indexInKnowledgeBase === (KnowledgeBase.length -1))  && (indexInFilteredKB === (filterKb.length -1)))return setTimeout(() => $('#nextBtn').hide() , 50);

        return ;
      }
      
      //ON CATEGORY PAGE
      setSelecedCategory(checkInCategory);
      if(KnowledgeBase.length > 0 ) setfilteredKnowledgeBase( KnowledgeBase.filter(v => v.CategoryName.replace(/\s/g, "-").toLowerCase() === urlParam).sort((a,b) => a.Index - b.Index ));
    }
  } 
  
  // Fetch All Active KnowledgeBase
  useEffect(()=>{
    let ignore = false;
    const fetchKnowledgeBase = async () => {
      try {
        if(KnowledgeBase.length === 0 ){
          const response = await getKnowledgeBaseList();
          if(response.length > 0 ){
            setKnowledgeBase(response);
          }
        }
      
      } catch (error) {
        Swal.fire({
          icon                : "error",
          titleText           : "Error!",
          text                : error.message,
          buttonsStyling      : false,
          confirmButtonClass  : "btn btn-brand",
        });
      }
    }
    if(!ignore){
      highlightResource();
      fetchKnowledgeBase();
    }
    return()=>{
      ignore = true;
    }
  },[])

// Filter All KnowledgeBase asper Category
  useEffect(()=>{
    if (Category.length > 0) filterKnowledgeBase();
  },[urlParam]);

  useEffect(()=>{
    if(KnowledgeBase.length > 0 ){
      const category = []
      KnowledgeBase.forEach(t => {if(category.findIndex(c => c.CategoryName === t.CategoryName) < 0) category.push({CategoryID:t.CategoryID, CategoryName:t.CategoryName, Details:t.Details, Image:t.Image,}) })
      setCategory(category);
    }
  },[KnowledgeBase])

 useEffect(()=>{
    if (Category.length > 0) filterKnowledgeBase();
  },[Category])

  useEffect(()=>{
    $('.nav-item').removeClass('active');
    $(`#${urlParam}`).addClass("active");
  },[filteredKnowledgeBase])

  const pushtoKnowledgeBase = (alias) => {
    history.push(`/knowledgebase/${alias}`)
    scrollPage();
  }
  const clearSearch = () => {
    $('#txtWithHint').val("");
    setIsSearching(false);
    if(Object.keys(selecedCategory).length !== 0) setSelecedCategory({});
  }

  const openChatWidget = () => { javascript:void(Tawk_API.toggle())};

  const nextHandler = (id) => {
    $('#prevBtn').show();
    //filterKnowledgeBase has kb of same category
    //find current kb index increase it by one for next kb in filteredkb
    //check if the current kb is at the last index of filtered kb
    const currentKBIndex = filteredKnowledgeBase.findIndex( k => k.ID === id);
    if(currentKBIndex !== (filteredKnowledgeBase.length -1)) return pushtoKnowledgeBase(filteredKnowledgeBase[currentKBIndex +1].Alias);
    
    // Check KnowledgeBase
    const currentKBIndexInKBList = KnowledgeBase.findIndex( k => k.ID === id );
    if( currentKBIndexInKBList !== (KnowledgeBase.length -1) ) return pushtoKnowledgeBase(KnowledgeBase[currentKBIndexInKBList +1].Alias);

  }

  const prevHandler = (id) => {
    $('#nextBtn').show();
    //Check FilteredKnowledgeBase
    const currentKBIndex = filteredKnowledgeBase.findIndex( k => k.ID === id );
    if(currentKBIndex !== 0 ) return pushtoKnowledgeBase(filteredKnowledgeBase[currentKBIndex -1].Alias);
    
    //Check KnowledgeBase
    const currentKBIndexInKBList = KnowledgeBase.findIndex( k => k.ID === id );
    if( currentKBIndexInKBList !== 0 ) return pushtoKnowledgeBase(KnowledgeBase[currentKBIndexInKBList -1].Alias);
    
  }
 
  return (<>
     <Helmet>
        <title> Insurance Software - Knowledge Base | We Are Here To Help - Insurebox.co </title>
        <meta name="description"
        content="Contact us today for any queries related to insurance agency management software. We are here to help you 24X7."/>
      </Helmet>
    <div onScroll={() => highlightResource()}>
      {selectedKnowledgeBase ?
        <section className="home-section" >
          <div className="container-case primary-content-container blog-post-list " >
            <div className="row-case">
              <div className="col-xs-12-c">
                <div className="blog-post row-case">
                  <div className="col-xs-12-c col-sm-10-c col-sm-offset-1-c col-md-10-c col-md-offset-1-c col-lg-8-c col-lg-offset-2-c">
                    <div className="blog-post-nav-links">
                      <NavLink className="u-tC-gray back-to-blog" to={`/knowledgebase/${selectedKnowledgeBase.CategoryName && selectedKnowledgeBase.CategoryName.replace(/\s/g, "-").toLowerCase()}`} 
                        onClick={() => setSelectedKnowledgeBase(null)}>
                        <i className="fas fa-arrow-left" style={{marginRight: "10px"}}></i>
                        Back
                      </NavLink>
                    </div>
                    <div className="partnership-card blog-post-container mb-0">
                      <div className="blog-post-body  padding-t10">
                        <div className="blog-post-details"></div>
                        <h1 className="blog-post-title text-center mb-4">{selectedKnowledgeBase.Title}</h1>
                        <div className="row mb-4">
                          <div className="col-xl-8 col-md-12">
                            <span className="blog-icons last" >
                              {`Category : ${selectedKnowledgeBase.CategoryName}`}
                            </span>
                          </div>
                          <div className="col-xl-4 col-md-12"></div> 
                        </div>            
                        <div className="blog-post-content" >{selectedKnowledgeBase.Description}</div>
                        <br/>
                        <div className="blog-post-content" dangerouslySetInnerHTML={{__html:selectedKnowledgeBase.Content}}></div>
                      </div>
                    </div>
                    <div className="row ml-0" style={{width:'100%'}}>
                      <div className="col-6">
                        <span id="prevBtn" className="u-tC-gray back-to-blog clickable" onClick={() => prevHandler(selectedKnowledgeBase.ID)} >
                          <i className="fas fa-arrow-left" style={{marginRight: "10px"}}></i>Prev
                        </span>
                      </div>
                      <div id="nextBtn" className="col-6 text-right">
                        <span className="u-tC-gray back-to-blog clickable"  onClick={() => nextHandler(selectedKnowledgeBase.ID)}>
                          Next <i className="fas fa-arrow-right ml-2" ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        :
        <>
          <SearchPage 
            dataList ={urlParam === "all"? KnowledgeBase : KnowledgeBase.filter(v => v.CategoryName.replace(/\s/g, "-").toLowerCase() === urlParam)}
            dataSetter={val => setfilteredKnowledgeBase(val)}
            setSearchState={val => setIsSearching(val)}
          />

          <section className="section-white padding-t50">
            <div className="container knowledgebase-container">
              <div className="categories">
                {filteredKnowledgeBase.length > 0 ? 
                  <>
                    { urlParam === "all" && !isSearching ? Category.map((c,i) => {
                        return (
                          <Link to={`/knowledgebase/${c.CategoryName.replace(/\s/g, "-").toLowerCase()}`}  className="category" key={i}>
                            <div className="category__img">
                              <img src={c.Image} alt={c.CategoryName} />
                            </div>  
                            <div className="category__body">
                              <div className="category__header">
                              {c.CategoryName}              
                              </div>
                              <div className="category__description">
                                {c.Details}
                              </div>             
                            </div>
                          </Link>
                        );
                      })
                      :
                      <div className="category_content">
                        <div className="back-nav-links">
                          <NavLink className="u-tC-gray back-to-category" to='knowledgebase/all' onClick={clearSearch}>
                              <i className="fas fa-arrow-left" style={{marginRight: "10px"}}></i>Back
                          </NavLink>
                        </div>
                        {Object.keys(selecedCategory).length !== 0 && 
                          <div className="category">
                            <div className="category__img">
                              <img src={selecedCategory.Image} alt={selecedCategory.CategoryName} />
                            </div>  
                            <div className="category__body">
                              <div className="category__header">
                              {selecedCategory.CategoryName}              
                              </div>
                              <div className="category__description">
                                {selecedCategory.Details}
                              </div>             
                            </div>
                          </div>
                        }
                        <div className="articles mb-4">
                          {filteredKnowledgeBase.map((k,i)=>{
                            return(
                              <div onClick={() => pushtoKnowledgeBase(k.Alias)} className="article clickable"  title={k.Title} key={i}>
                                <div className="article__header">
                                  {k.Title}              
                                </div>
                                <div className="article__body">
                                  {k.Description}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    }
                  </>
                  :
                  <div className="row">
                    <div className="col-sm-12 text-center padding-10">
                      <img src={noSupport} alt="no-support" />
                      <h4 className="img-empty">Coming Shortly</h4>
                    </div>
                  </div>
                }
              </div>
              {/* Still have questions? */}
              <div className="questions">
                <h2>Still have questions?</h2>
                <div className="decisions">
                  <div className="decision">
                    <div className="decision__img">
                      <img  src={phonecall} alt="decision 1" style={{width: 45}}  />
                    </div>
                    <div className="decision__header">Call Us </div>
                    <div className="decision__body">
                      <a href="tel:+91 9221008090" target="_self">+91 9221008090</a>
                    </div>
                  </div>
                  <div className="decision">
                    <div className="decision__img">
                      <img src={mail} alt="decision 2" style={{width: 60}} />
                    </div>
                    <div className="decision__header">Email Us </div>
                    <div className="decision__body">
                      <a href="mailto:support@insurebox.co" target="_blank">support@insurebox.co</a>
                    </div>
                  </div>
                  <div className="decision">
                    <div className="decision__img">
                      <img src={customer} alt="decision 3" style={{width: 50}} />
                    </div>
                    <div className="decision__header"> Get Support Online </div>
                    <div className="decision__body">
                      <span className="text-primary clickable" id="openChat" onClick={openChatWidget} >Start live chat </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      }
    </div>
  </>);
};
export default KnowledgeBaseList;
