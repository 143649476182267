import React,{useState}            from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Footer                      from "../components/global/Footer";
import Header                      from "../components/global/Header";
import HomeSection                 from "../components/home/HomeSection";
import FeaturesSection             from "../components/home/FeaturesSection";
import Testimonial                 from "../components/home/Testimonial";
import ReportSection               from "../components/home/ReportSection";
import KnowledgeBaseList           from "../components/pages/KnowledgeBaseList";
import VideoTutorial               from "../components/pages/VideoTutorial";
import Blogs                       from "../components/pages/Blogs";
import PrivacyPolicy               from "../components/pages/PrivacyPolicy";
import RefundPolicy                from "../components/pages/RefundPolicy";
import TermsConditions             from "../components/pages/TermsConditions";
import ContactUsModal              from "../components/modals/ContactUsModal";
import SupportModal                from "../components/modals/SupportModal";
import Pricing                     from "../components/pages/Pricing";
import BookDemo                    from "../components/pages/BookDemo";
import About                       from "../components/pages/About";
import { Helmet }                  from "react-helmet";
import EmailVerificationSuccessfull from "../components/pages/EmailVerificationSucessfull";
import EmailVerificationFailure     from "../components/pages/EmailVerificationFailure";


function Admin() {

    const [showContactUs,       setshowContactUs] = useState(false);
    const [showSupportModal,    setSupportModal]  = useState(false);

    const modalHandler = (modal) => {
        switch(modal){
            case 'contactUs': 
                setshowContactUs(true);
                break;
            case 'support': 
                setSupportModal(true);
                break;
            default:
                break;
        }
    }


    return (
        <>
            <Helmet>
                <title>
                    Insurance Agency Management Software | Insurance Agent Software - Insurebox.co
                </title>
                <meta name="description"
                    content="Insurebox is a cloud based Insurance Agency Management software which will help you manage your insurance agency business with more  efficiency and help improve your business."
                />
            </Helmet>

            <Switch>
                 <Route exact path="/email-verify-successfull" >
                    <EmailVerificationSuccessfull/>
                </Route>
                <Route path="/email-verify-failure">
                    <EmailVerificationFailure/>
                </Route>
                <Route path="/">
                    <Header  modalHandler={modalHandler}  />
                    <Switch>
                        <Route path="/bookdemo">
                            <BookDemo />
                        </Route>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/pricing">
                            <Pricing />
                        </Route>
                        <Route path="/terms-condition">
                            <TermsConditions />
                        </Route>
                        <Route path="/refund-policy">
                            <RefundPolicy />
                        </Route>
                        <Route path="/privacy-policy">
                            <PrivacyPolicy />
                        </Route>
                        <Route path="/video-tutorial/:category">
                            <VideoTutorial />
                        </Route>
                        <Route path="/blogs/:tag">
                            <Blogs />
                        </Route>
                        <Route path="/knowledgebase/:category">
                            <KnowledgeBaseList />
                        </Route>
                        <Route path="/blogs">
                            <Redirect  to="/blogs/all" />
                        </Route>
                        <Route path="/">
                            <HomeSection />
                            <FeaturesSection />
                            <ReportSection />
                            <Testimonial />
                        </Route>
                    </Switch>
                    <Footer
                        modalHandler={modalHandler}
                    />
                </Route>
            </Switch>
                        
            {showContactUs && 
                <ContactUsModal 
                    show={showContactUs}
                    onDismissModal={() => setshowContactUs(false)}
                />
            }
            {showSupportModal && 
                <SupportModal 
                    show={showSupportModal}
                    onDismissModal={() => setSupportModal(false)}
                />
            }
        </>
    );
}

export default Admin;
