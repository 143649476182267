import $              from "jquery"
import fbLogo         from "../../assets/images/facebook.png";
import ytLogo         from "../../assets/images/youtube.png";
import tweetLogo      from "../../assets/images/twitter.png";
import instaLogo      from "../../assets/images/instagram.png";
import lnLogo         from "../../assets/images/linkedin.png";
import otherImg       from "../../assets/images/otherImg.png";


export function appUrl() {
  window.location.href = "https://app.insurebox.co/signup";
};

export function StartProcessing(btn) {
  btn
  .addClass("kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light")
  .attr("disabled", true);
}
  
export function StopProcessing(btn) {
  btn
  .removeClass("kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light")
  .attr("disabled", false);
}

export const getLogo = (type) => {
  let logo = otherImg;
  switch (type) {
    case "Facebook":
      logo = fbLogo;
      break;
    case "Youtube":
      logo = ytLogo;
      break;
    case "Twitter":
      logo = tweetLogo;
      break;
    case "Instagram":
      logo = instaLogo;
      break;
    case "Linkedin":
      logo = lnLogo;
      break;
    case "gift":
      logo = giftLogo;
      break;
    default:
      logo = otherImg;
  }
  return logo;
};

export const scrollPage = () => {
  $("#nav-ib").removeClass("opaque");
  window.scrollTo({top:0, left:0, behavior:"auto"})
}

export const highlightResource = () => {
  $("#ul-list").children("li").removeClass('current');
  $("#menuResources").addClass('current'); 
}
export const highlightNavlink = (id) => {
  $("#ul-list").children("li").removeClass('current');
  $(`#${id}`).parent().addClass('current'); 
}

export const unHightlightNavlink = () => {
  $("#ul-list").children("li").removeClass('current');
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const titleCase = (data) =>
  !data || data === ""
    ? ""
    : data
        .split(" ")
        .map((d) => capitalize(d))
        .join(" ");

export const capitalize = (name) =>
!name || name === "" ? "" : `${name[0].toUpperCase()}${name.slice(1)}`;

