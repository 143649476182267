import React  from "react";

const SearchPage = (props) => {

  const {
    dataSetter,
    dataList,
    setSearchState
  } = props;

  const onSearch = ({target}) => {
    if(target.value === ""){
      setSearchState && setSearchState(false);
      return dataSetter(dataList);
    }
    setSearchState && setSearchState(true);
    const matchedData = dataList.filter(d =>{
      let matchString = `${d.Title} ${d.Description} ${d.CategoryName} ${d.Content && d.Content}`;
      let matchFound = 0;
      const searchArr = target.value.split(' ');
      searchArr.forEach(term => {
        matchFound += matchString.toLowerCase().includes(term.toLowerCase())?1:0;
      });
      return matchFound === searchArr.length;
    })
    dataSetter(matchedData);
  }

  return (
    <React.Fragment>
      <section className="section-blue-resource" id="hom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="heading-resource">We are here to help!</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9 hc-search-input">
              <i
                className="fa fa-search fa-lg search-icon"
                aria-hidden="true">
              </i>
              <input
                id="txtWithHint"
                runat="server"
                className="align-content-center special"
                type="text"
                placeholder="Search.."
                onChange={onSearch}>
              </input>
              <button
                className="align-content-center special"
                id="Button1"
                runat="server"
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default SearchPage;
