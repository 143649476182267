import React, {useState,useEffect }         from "react";
import $                                    from "jquery";
import * as helper                          from "../global/Helper";
import Select                               from "react-select";
import Swal                                 from "sweetalert2";
import {getSuppotQueries,addSupportTicket}  from "../api/webAPI";

import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";

const RequestDemoModal = (props) => {

  const [supportData,             setSupportData]   = useState({});
  const [queryList,                 setqueryList]   = useState([]);

  const supportDataHandler =  (key, value) => {
    setSupportData(d=>{
      return {...d,[key]:value};
    });
  };

  
  const submitContactUs = async (e) => {
    e.preventDefault();
    var form = $('#support-form');
    form.validate().destroy();
    form.validate({
      rules:{
        txtSupportName:{
          required:true
        },
        txtSupportEmail:{
          required:true,
        },
        supportQuery:{
          required: {
            depends: function () {
              return !supportData.QueryID;
            },
          },
        },
      }
    });

    if(!form.valid()){
      if ($("#txtSupportName-error").text() !== "") {
        $("#txtContactName").addClass("is-invalid");
      }
      if ($("#txtSupportEmail-error").text() !== "") {
        $("#txtContactEmail").addClass("is-invalid");
      }
      return;
    }

    try {
      helper.StartProcessing($("#btnSubmit"));
      //GENERATE TICKET NO
      const Ticket = `W${Date.now()}`;
      await addSupportTicket({...supportData,Ticket});

      Swal.fire({
        icon                  : "success",
        titleText             : "Request Submitted",
        text                  : "Thank You..!",
        showConfirmButton     : false,
        timer                 : 1500,
      });
      $("#Support-modal").modal("hide");
    } catch (err) {
      helper.StopProcessing($("#btnSubmit"));
      Swal.fire({
        icon                  : "error",
        titleText             : "Error!",
        text                  : err.message,
        buttonsStyling        : false,
      confirmButtonClass      : "btn btn-brand",
      });
    }  
  }
  
  useEffect(() => {
    let ignore = false;
    const fetchSupportQueries = async () => {
      try {
        const response = await getSuppotQueries();
        if(response) {
          response.map(a => a.Active === "Y" && setqueryList( data => [...data, { label : a.Query, value : a } ]))
        }
      } catch (error) {
        Swal.fire({
          icon                  : "error",
          titleText             : "Error!",
          text                  : error.message,
          buttonsStyling        : false,
          confirmButtonClass    : "btn btn-brand",
        });
      }
    }
    if(!ignore) fetchSupportQueries();
    return () => {
      ignore = true;
    }
  }, []);

  

  //Modal Opener
  useEffect(() => {
    $("#Support-modal").modal({
      backdrop: "static",
    });

    $("#Support-modal").on("hidden.bs.modal", function () {
      $(".modal").hasClass("show") && $("body").addClass("modal-open");
      props.onDismissModal();
    });

    $("#Support-modal").modal("toggle");
  }, [props.show]);



  return (
    //  <!--Modal Request Demo -->
    <div
      className="modal fade bd-example-modal-lg"
      id="Support-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalSupportTitle"
      aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalSupportTitle">
              Support
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div id="modalSupportHolder">
              <div id="messageSupportHolder"></div>
              <form id="support-form" className="contact">
                <input
                  className="contact-input white-input margin-t0"
                  name="txtSupportName"
                  id="txtSupportName"
                  placeholder="Name*"
                  type="text"
                  value={supportData.Name || ""}
                  onChange={({ target }) =>supportDataHandler("Name", helper.titleCase(target.value))}
                />
                <input
                  className="contact-input white-input"
                  name="txtSupportEmail"
                  id="txtSupportEmail"
                  placeholder="Your Email*"
                  type="email"
                  value={supportData.Email || ""}
                  onChange={({ target }) =>supportDataHandler("Email",(target.value))}
                />
                <Select
                  id="supportQuery"
                  name="supportQuery"
                  placeholder="Select Query"
                  className="margin-t20"
                  options={queryList}
                  value={supportData.QueryID && queryList.find(q=>q.value === supportData.QueryID)}
                  onChange={(selectedQuery)=>{
                    if(selectedQuery){
                      supportDataHandler("QueryID",selectedQuery.value.ID)
                      supportDataHandler("QueryName",selectedQuery.value.Query)
                    }
                  }}
                />
                <input
                  className="contact-input white-input"
                  name="txtSupportContactNo"
                  id="txtSupportContactNo"
                  placeholder="Contact No"
                  type="text"
                  value={supportData.ContactNo || ""}
                  onChange={({ target }) =>supportDataHandler("ContactNo", target.value)}
                />
                <textarea
                  className="contact-commnent white-input"
                  rows="2"
                  cols="20"
                  name="txtSupportMessage"
                  id="txtSupportMessage"
                  placeholder="Message"
                  value={supportData.Message || ""}
                  onChange={({ target }) =>supportDataHandler("Message", target.value)}>
                </textarea>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal">
              Close
            </button>
            <button type="button" id="btnSubmit" className="btn btn-primary" onClick={submitContactUs}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    // {/* <!-- /#Modal --> */}
  );
};
export default RequestDemoModal;
