import React,{useState, useEffect}                from "react";
import SearchPage                                 from "./SearchPage";
import $                                          from "jquery";
import novideo                                    from "../../assets/images/no_video.png";
import Swal                                       from "sweetalert2";
import {getVideoTutorialAll,}                     from "../api/webAPI";
import {highlightResource,capitalizeFirstLetter}  from "../global/Helper";
import {NavLink, useRouteMatch, useHistory}       from "react-router-dom";
import { Helmet }                                 from "react-helmet";

const VideoTutorial = () => {

  const [VideoTutorials,                   setVideoTutorials]          =    useState([]);
  const [filteredVideoTutorials,           setfilteredVideoTutorials]  =    useState([]);
const [tutorialCategory,                 settutorialCategory]          =    useState([]);
  const match                                                          =    useRouteMatch();
  const history                                                        =    useHistory();
  const urlCategory                                                    =    match.params.category;

  const filterVideoTutorial = () => {
    if(urlCategory === "all"){
      setfilteredVideoTutorials(VideoTutorials);
    }else{
      if(tutorialCategory.findIndex( t => t.CategoryName.replace(/\s/g, "-").toLowerCase() === urlCategory) === -1 ) return history.push("/video-tutorial/all");
      if(VideoTutorials.length > 0) setfilteredVideoTutorials(VideoTutorials.filter(v => v.CategoryName.replace(/\s/g, "-").toLowerCase() === urlCategory));
    }
  }

// Fetch All Active VideoTutorials & tutorialCategory
  useEffect(()=>{
    let ignore = false;
    const fetchVideoTutorials = async () => {
      try {       
        const response = await getVideoTutorialAll();
        if(response.length > 0 )   setVideoTutorials(response); 
      } catch (error) {
        Swal.fire({
          icon                : "error",
          titleText           : "Error!",
          text                : error.message,
          buttonsStyling      : false,
          confirmButtonClass  : "btn btn-brand",
        });
      }
    }
    if(!ignore) {
      highlightResource();
      fetchVideoTutorials();
    }
    return()=>{
      ignore = true;
    }
  },[])

  // Filter All VideoTutorials asper urlCategory
  useEffect(()=>{
    if (tutorialCategory.length > 0)
      filterVideoTutorial();
  },[urlCategory])

  useEffect(()=>{
    if (VideoTutorials.length > 0){
      const category = []
      VideoTutorials.forEach(t => {if(category.findIndex(c => c.CategoryName === t.CategoryName) < 0) category.push({CategoryName:t.CategoryName,CategoryID:t.CategoryID,CategoryIndex:t.CategoryIndex}) })
      settutorialCategory(category.sort((a, b) => a.CategoryIndex - b.CategoryIndex));
    }     
  },[VideoTutorials])

  useEffect(()=>{
    if (tutorialCategory.length > 0)
      filterVideoTutorial();
  },[tutorialCategory])

  useEffect(()=>{
    $('.nav-item').removeClass('active');
    $(`#${urlCategory}`).addClass("active");
  },[filteredVideoTutorials])

 
  return (<>
    <Helmet>
      <title> Insurance Software - Video Tutorials To Help You | Insurebox.co </title>
      <meta name="description" content="Check out our insurance software video tutorials on how to add new clients, upload, edit or delete renewal data, adding new policy and adding emailing quotations."/>
    </Helmet>
    <div onScroll={() => highlightResource()}>
      <SearchPage
        dataList ={urlCategory === "all"? VideoTutorials : VideoTutorials.filter(v => v.CategoryName.replace(/\s/g, "-").toLowerCase() === urlCategory)}
        dataSetter={val => setfilteredVideoTutorials(val)}
      />
      <section className="section-white padding-t50">
        <div className="container">
          {filteredVideoTutorials.length > 0 ? 
            <>
              <div className="postCats__wrap">
                <ul className="nav postCats">
                  <li className="nav-item active" id="all">
                    <NavLink className="nav-link" to="/video-tutorial/all">All</NavLink>
                  </li>
                  
                  {tutorialCategory.length > 0 && tutorialCategory.map((t,i) => {
                    return(
                      <li className="nav-item" id={`${t.CategoryName.replace(/\s/g, "-").toLowerCase()}`} key={i}>
                        <NavLink className="nav-link" to={`/video-tutorial/${t.CategoryName.replace(/\s/g, "-").toLowerCase()}`} >{t.CategoryName && capitalizeFirstLetter(t.CategoryName)}</NavLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="row margin-t30">
                {filteredVideoTutorials.map((v,i)=>{
                  return(
                    <div className="col-xl-6 col-md-6" key ={i}>
                      <div className="card resource-card margin-10">
                        <iframe className="iframe-video" src={v.Link} frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"></iframe>
                        <div className="card-body resource padding-10 margin-t15">
                          <h4 className="card-title">{v.Title}</h4>
                          <p className="card-text">{v.Description}</p>
                        </div>
                      </div>
                    </div>
                  )
                })}       
              </div>
            </>
            :
            <div className="row">
              <div className="col-sm-12 text-center padding-10">
                <img src={novideo} alt="no-support" />
                <h4 className="img-empty">Sorry ! No Videos</h4>
              </div>
            </div>
          }
        </div>
      </section>
    </div>
  </>);
};
export default VideoTutorial;
