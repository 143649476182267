import React,{useEffect}                    from "react";
import ibLogo from '../../assets/images/banner-logo.png'

const EmailVerificationFailure = () => {

    useEffect(()=>{
        setTimeout(()=> window.location.replace("https://insurebox.co/"), 3000)
    },[])

    return (
        <>
            <div style={{margin: "8%"}}>
                <div className="text-center margin-t50" >
                    <img id="imgLogo" src={ibLogo} alt="ib-logo" className="" style={{color:"blue",height:"38px",alignItems:"center",marginBottom:"81px"}} />
                </div>
                <section className="section-grey" >
                    <div className="text-center">        
                        <span><i className="fa fa-times-circle-o fa-5x" aria-hidden="true" style={{color:"red",alignItems:"center",fontWeight:"lighter"}}></i>
                        <h3 className="margin-t30">Email Verfication Failed</h3>
                        </span>
                    </div>
                </section>
            </div>
        </>
                    
    );
};

export default EmailVerificationFailure;