import React,{useEffect}                    from "react";
import {scrollPage, unHightlightNavlink}    from "../global/Helper";
import $                                    from "jquery";
import group                                from "../../assets/images/customer-success.png";
import problem                              from "../../assets/images/puzzle.png";
import security                             from "../../assets/images/security.png";
import customer                             from "../../assets/images/customer-service.png";
import Helmet from "react-helmet";

 

const About = () => {

    useEffect(()=>{
        scrollPage();
        unHightlightNavlink();
    },[]);  

    useEffect(()=>{
        const maxHeight = $($(".about-list").sort((a, b) => $(b).height() - $(a).height())[0]).height();
        $(".about-list").css('min-height', `${maxHeight}px`); 
    },[])
   

    return(
        <>
        <Helmet>
            <title>About Us</title>
            <meta name="description" content="InsureBox was founded by a team of experienced professionals with over 20 years of experience in both insurance and technology sectors, with the sole vision to empower you to manage and organize your insurance portfolio." />
        </Helmet>
        <section className="section-grey pb-2 margin-l10 margin-r10 " onScroll={() => unHightlightNavlink()}>
            <div className="container margin-b100">
                <div className="section-header padding-0 wow fadeIn animated" data-wow-offset="120" data-wow-duration="1.5s">
                    <h2 className="about-title">About Us</h2>
                    <div className="colored-line"></div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h5 className="about-head-title">Empowering You to Manage Your Insurance Portfolio in the New Digital World</h5>
                        <p className="about-paragragh ">Whether you own an insurance intermediary, manage your company’s insurance portfolio, or just want to organize your personal family insurance, InsureBox provides you all the solutions to take control.</p>
                        <p className="about-paragragh ">InsureBox was founded by a team of experienced professionals with over 20 years of experience in both insurance and technology sectors, with the sole vision to empower you to manage and organize your insurance portfolio in a professional and efficient way and help achieve business or personal success.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h5 className="about-head-title"> Our Vision </h5>
                        <p className="about-paragragh">To empower intermediaries, business owners, and individuals to seamlessly manage and organize their insurance portfolios using our best-in-class technology platform. </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h5 className="about-head-title margin-b30"> Our Values </h5>
                    </div>
                </div>
                <div className="row pricing-row md">

                    {/* {Customer Success} */}
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="about-box">
                            <ul className="about-list">
                                <li className="about-title">Customer Success</li>
                                <img className="about-icons " src={group}  />
                                <li className="about-text">We win when our customers deliver superior business performance using our new age software stack.</li>
                            </ul> 
                        </div>
                    </div>

                    {/* {Problem Solving} */}
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="about-box">
                            <ul className="about-list">
                                <li className="about-title">Problem Solving</li>
                                <img className="about-icons" src={problem} />
                                <li className="about-text">Our endeavour is to constantly innovate and improve our product offering to solve problems and generate better outcomes for our clients.</li>
                            </ul> 
                        </div>
                    </div>

                    {/* {Security} */}
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="about-box">
                            <ul className="about-list">
                                <li className="about-title">Security</li>
                                <img className="about-icons" src={security}  /> 
                                <li className="about-text"> Your data security is our No. 1 priority ! We secure your data in the world's best cloud and data servers.</li>
                            </ul> 
                        </div>
                    </div>

                    {/* {Excellence in Customer Service} */}
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="about-box">
                            <ul className="about-list">
                                <li className="about-title">Customer Priority</li>
                                <img className="about-icons" src={customer}  />
                                <li className="about-text">We are always there for our customers and strive to deliver best-in-class service and troubleshooting.</li>
                            </ul> 
                        </div>
                    </div>

                </div>
            </div>
        </section>
        </>
    )
}

export default About;