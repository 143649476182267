import {axiosPost, axiosGet} from "./axiosFunctions";

const addContactUsUrl               = "/api/homepage/addContactUs";
const addSupportTicketUrl           = "/api/homepage/addSupport";
const getSupportQueriesUrl          = "/api/homepage/getSupportMasterAll";
const getVideoTutorialUrl           = "/api/applications/getVideoTutorial";
const getKnowledgeBaseUrl           = "/api/applications/getKnowledgeBase";
const getKnowledgeBaseAliasUrl      = "/api/applications/getKnowledgeBaseByAlias";
const getVideoTutorialCategoryUrl   = "/api/applications/getVideoTutorialCategory";
const getBlogsUrl                   = "/api/blog/getBlogs";
const getBlogByAliasUrl             = "/api/blog/getBlogByAlias";
const getAuthorBacklinksUrl         = "/api/blog/getAuthorBacklinksActive";
const getFAQUrl                     = "/api/applications/getFAQ";  
const getTestimonialUrl             = "/api/homepage/getTestimonialAll"  


//Add API's =>

//Contact Us -->
export const addContactUs = async (contactUsData) => {
    const response = await axiosPost(
        addContactUsUrl,
        {contactUsData},
    );
    return response;
};
//Support    -->
export const addSupportTicket = async (supportData) => {
    const response = await axiosPost(
        addSupportTicketUrl,
        {supportData},
    );
    return response;
};



//Get API's =>

//Support    -->
export const getSuppotQueries = async () => {
    const response = await axiosGet(getSupportQueriesUrl);
    return response;
}

//Video Tutorials -->
export const getVideoTutorialAll = async () =>{
    const response = await axiosGet(
        getVideoTutorialUrl,
    );
    return response;
};
export const getVideoTutorialCategoryAll = async () => {
    const response = await axiosGet(getVideoTutorialCategoryUrl, );
    return response;
};
//Knowledge Base -->
export const getKnowledgeBaseList= async () =>{
    const response = await axiosGet(
        getKnowledgeBaseUrl,
    );
    return response;
};
//Check KnowledgeBase By Alias
export const getKnowledgeBaseAlias = async (Alias) => {
    const response = await axiosGet( `${getKnowledgeBaseAliasUrl}/${Alias}`, );
    return response;
  };
//Blogs -->
export const getBlogList = async() =>{
    const response = await axiosGet(getBlogsUrl,);
    return response;
}
export const getAuthorBacklinks = async(authorID) => {
    const response = await axiosGet(`${getAuthorBacklinksUrl}/${authorID}`,);
    return response;
}
//Check Blog By Alias
export const getBlogByAlias = async (blogAlias) => {
    const response = await axiosGet(`${getBlogByAliasUrl}/${blogAlias}`, );
    return response;
}
//FAQ -> 
export const getFAQ = async () =>{
    const response  = await axiosGet(getFAQUrl,);
    return response; 
}
//Testimonials->
export const getTestimonialAll = async () => {
    const response = await axiosGet(getTestimonialUrl,);
    return response;
}